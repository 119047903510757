import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { myContext } from '../App';
import LinkedIn from './linkedIn';
import { fetchLinkedInFeed } from '../service/api';

export function ContactUs() {

    const [showSubmittedMessage, setShowSubmittedMessage] = useState(true);
    const [linkedInFeed, setLinkedInFeed] = useState([]);
    const buttonRef = useRef(null);

    useEffect(() => {
        const fetchFeed = async () => {
            const feed = await fetchLinkedInFeed();
            setLinkedInFeed(feed.data.elements);
        };
        fetchFeed();
    }, []);

    useEffect(() => {
        if (buttonRef.current)
            buttonRef.current.click()
    }, [linkedInFeed]);

    // use useNavigate hook to redirect the user to any other page
    const navigate = useNavigate();
    // Import and use the useContext hook to access the data from app.jsx
    const footerContext = useContext(myContext);

    const navigateTo = (path) => {
        footerContext.setRender(!footerContext.render);
        navigate(path)
    }


    const onContactUsClick = async () => {
        setShowSubmittedMessage(false);
    }

    return <div>
        {/* gradient banner starts  */}
        <div className="text-banner primary-gradient-bg d-flex align-items-center justify-content-center ">
            <h1 className="font-60 text-center">GET CONNECTED</h1>
        </div>
        {/* gradient banner ends  */}
        {/* contact form starts here  */}
        <div className="container mb-5 pb-3">
            <div className="row justify-content-center px-4 px-md-0">
                <h2 className="text-center mt-5 pt-4 mb-5 text-uppercase grey-color-v1">Our team is here to help - Contact us today!
                </h2>
                <a type="submit" href="mailto:contact@cfs.canon.com?subject=Canon Financial Services Website Inquiry" className={`header-button px-5 text-uppercase btn-sm w-auto  text-decoration-none`} onClick={() => { onContactUsClick() }}>Contact Us</a>
                <div className="custom-form-text info-msg-margin text-center" hidden={showSubmittedMessage}>
                    Thanks for your interest! If your default email application did not open, please email us at <a href="mailto:contact@cfs.canon.com?subject=Customer Service Website Inquiry
                ">contact@cfs.canon.com.</a>
                </div>
            </div>
        </div>

        {/* contact form ends here  */}
        {/* recognized banner starts here */}
        <div className="grey-bg w-100">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-3">
                        <div className="d-flex justify-content-center">
                            <a href="https://www.monitordaily.com/monitor100/2023-monitor-100/" target="_blank"><img src="images/monitor-badge.svg" alt="monitor-badge" className="mb-2 pb-1" /></a>
                        </div>
                    </div>
                    <div className="col-md-5 my-5 my-lg-0 ms-md-5 ms-lg-0  text-center text-md-start text-lg-start">
                        <h3 className="mb-4 pb-2 grey-color-v1">Proud to be recognized</h3>
                        <p className="font-16">Offering a variety of financing plans to help customers acquire the equipment, systems, and
                            software needed to drive future innovation and business profits.</p>
                    </div>
                </div>
            </div>
        </div>
        {/* recognized banner ends here */}

        {/* Linkedin integration starts here */}
        <div className='testimonial-gradient-bg post-wrapper py-4'>
                    <h2 className="text-center text-uppercase text-white mt-5">
                    Stay connected with Canon financial services
                    </h2>
            <div id="post-carousel" className="carousel slide p-5 posts-carousel d-flex justify-content-center mx-auto" data-bs-ride="carousel" data-bs-pause="false">
                <div className="carousel-indicators">
                    {linkedInFeed && linkedInFeed.map((post, index) => (
                        index == 0 ? <button key={post.id} ref={buttonRef} type="button" data-bs-target="#post-carousel" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" /> :
                            <button key={post.id} type="button" data-bs-target="#post-carousel" data-bs-slide-to={index} aria-label={`Slide ` + (index + 1)} />
                    ))}
                </div>
                <div className="carousel-inner bg-white mb-4 mb-xxl-3 p-3 p-lg-4 rounded-3 shadow-lg">
                    {linkedInFeed ? linkedInFeed.map((post, index) => (
                        <LinkedIn key={post.id} post={post} activeClass={index === 0 ? " active" : ""} />
                    )) : "Unable to load LinkedIn feeds"}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#post-carousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#post-carousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>

        {/* Linkedin integration ends here */}

        {/* extra help starts here */}
        <div className="container mt-4 mb-4 pb-3">
            <div className="row justify-content-center gap-5">
                <div className="col-12 w-100">
                    <h2 className="text-center text-uppercase grey-color-v1 mt-5">
                        Need Some Extra Help? Contact Customer Support!
                    </h2>
                </div>

                <div className="col-12">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="d-flex align-items-center gap-3 my-4 my-lg-0">
                                <span><img src="images/home-icon.svg" alt="home-icon" /></span>
                                <span className="font-16 font-regular">158 Gaither Drive,
                                    <span className='d-block'>Mount Laurel, NJ 08054</span></span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-lg-center col-12 col-md-6 col-lg-3">
                            <div className="d-flex align-items-center gap-3 my-4 my-lg-0">
                                <span><img src="images/mail.svg" alt="home-icon" /></span>
                                <span className="font-16 font-regular"><a href="mailto:customer@cfs.canon.com?subject=Customer Service Website Inquiry" className="text-decoration-none primary-color">customer@cfs.canon.com</a></span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-lg-center col-12 col-md-6 col-lg-3">
                            <div className="d-flex align-items-center gap-3 my-4 my-lg-0">
                                <span><img src="images/call-icon.svg" alt="home-icon" /></span>
                                <span className="font-16 font-regular"><a href="tel:(800) 220-0330" className="text-decoration-none primary-color">(800) 220-0330</a></span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-lg-center col-12 col-md-6 col-lg-3">
                            <div className="d-flex align-items-center gap-3 my-4 my-lg-0">
                                <span><img src="images/faq-icon.svg" alt="home-icon" /></span>
                                <span className="font-16 font-regular"><a onClick={() => { navigateTo("/Faq") }} className="text-decoration-none primary-color cursor-pointer">Frequently Asked Questions</a></span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* card structure */}
                <div className='row w-100 justify-content-between custom-row-gap'>
                    <div className='col-12 col-md-6 col-xl-4 contact-card-wrapper'>
                        <div className='d-flex p-4'>
                            <div className='contact-icon d-flex justify-content-center align-items-center'>
                                <span className='font-18 font-regular'>SS</span>
                            </div>
                            <div className='contact-card-details'>
                                <h5 className='font-bold font-22'>Sales Support</h5>
                                <span className='hr-line-decor d-inline-block w-100 mb-2'></span>
                                <div className='contact-card-content'>
                                    <p className='font-14'>
                                        For assistance in obtaining equipment financing for your business, contact a representative today: <a href="mailto:contact@cfs.canon.com" className='text-decoration-none privacy-anchor-style break-word'>Contact@cfs.canon.com</a>
                                    </p>
                                    <p className='font-14'>
                                        If you are a vendor, dealer, or manufacturer looking to offer equipment financing, contact vendor finance today: <a href="mailto:vendorFinance@cfs.canon.com" className='text-decoration-none privacy-anchor-style break-word'>VendorFinance@cfs.canon.com</a>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-xl-4 contact-card-wrapper'>
                        <div className='d-flex p-4'>
                            <div className='contact-icon d-flex justify-content-center align-items-center'>
                                <span className='font-18 font-regular'>IF</span>
                            </div>
                            <div className='contact-details'>
                                <h5 className='font-bold font-22'>Inventory Finance</h5>
                                <span className='hr-line-decor d-inline-block w-100 mb-2'></span>
                                <div className='contact-card-content'>
                                    <p className='font-14'>
                                        If you are looking for more information about our inventory finance programs, contact us.
                                    </p>
                                    <p className='font-14 mb-1'>
                                        Email: <a href="mailto:inventoryfinance@cfs.canon.com" className='text-decoration-none privacy-anchor-style break-word'>inventoryfinance@cfs.canon.com</a>
                                    </p>
                                    <p className='font-14'>
                                        Phone: <a href="tel:(888) 220-1636" className='text-decoration-none privacy-anchor-style'>(888) 220-1636</a>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-xl-4 contact-card-wrapper'>
                        <div className='d-flex p-4'>
                            <div className='contact-icon d-flex justify-content-center align-items-center'>
                                <span className='font-18 font-regular'>CS</span>
                            </div>
                            <div className='contact-details w-100'>
                                <h5 className='font-bold font-22'>Customer Service</h5>
                                <span className='hr-line-decor d-inline-block w-100 mb-2'></span>
                                <div className='contact-card-content'>
                                    <p className='font-14 mb-1'>
                                        Email: <a href="mailto:customer@cfs.canon.com" className='text-decoration-none privacy-anchor-style'>customer@cfs.canon.com</a>
                                    </p>
                                    <p className='font-14'>
                                        Phone: <a href="tel:(800) 220-0330" className='text-decoration-none privacy-anchor-style'>(800) 220-0330</a>
                                    </p>
                                    <a href="https://mycfs.cfs.canon.com/" target='__blank' className='text-decoration-none privacy-anchor-style font-14'>Access Our Customer Portal</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        {/* extra help ends here */}
        {/* gradient band starts  */}
        <div className="w-100 primary-gradient-bg p-3 contact-cta-sm">
            <p className="font-18 font-regular secondary-color text-center mb-0 d-flex flex-column flex-lg-row justify-content-center align-items-center gap-3">
                <span>
                    <span className="font-bold mb-md-0">GO GREEN with Canon.</span> <span>Choose Paperless Billing and Pay Online Today!</span>
                </span>
                <a className="primary-outline-btn font-bold font-16 text-uppercase py-2 px-3 text-decoration-none" href="https://mycfs.cfs.canon.com/" target='_blank'>
                    Make a Payment
                </a>
            </p>
        </div>
        {/* gradient band ends  */}
    </div>
}