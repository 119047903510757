import React, { useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router';
import { myContext } from '../App';

export function Industries() {
  const buttonRef = useRef(null);

  useEffect(() => {
    buttonRef.current.click();
  }, []);

  // use useNavigate hook to redirect the user to any other page
  const navigate = useNavigate();
  // Import and use the useContext hook to access the data from app.jsx
  const footerContext = useContext(myContext);

  const navigateTo = (path) => {
    footerContext.setRender(!footerContext.render);
    navigate(path)
  }

  return <>
    {/* Industries banner starts here  */}
    <div className="position-relative">
      <div
        id="homeBanner"
        className="carousel slide home-banner-height mb-1"
        data-bs-ride="carousel"
        data-bs-pause="false"
      >
        <div className="carousel-indicators custom-carousel-indicators custom-carousel-indicators-industries">
          <button
            type="button"
            data-bs-target="#homeBanner"
            data-bs-slide-to={0}
            className="active"
            aria-current="true"
            aria-label="Slide 1"
            ref={buttonRef}
          />
          <button
            type="button"
            data-bs-target="#homeBanner"
            data-bs-slide-to={1}
            aria-label="Slide 2"
          />
          <button
            type="button"
            data-bs-target="#homeBanner"
            data-bs-slide-to={2}
            aria-label="Slide 3"
          />
          <button
            type="button"
            data-bs-target="#homeBanner"
            data-bs-slide-to={3}
            aria-label="Slide 4"
          />
        </div>
        <div className="carousel-inner home-banner-height">
          <div
            className="carousel-item active home-banner-height"
            data-bs-interval={5000}
          >
            <img
              src="images/Industry banner image.jpg"
              className="d-block img-fluid obj-fit-cover w-100 home-banner-height"
              alt="..."
            />
          </div>
          <div
            className="carousel-item home-banner-height"
            data-bs-interval={7000}
          >
            <img
              src="images/still.png"
              className="d-block img-fluid obj-fit-cover  w-100 home-banner-height"
              alt="..."
            />
          </div>
          <div
            className="carousel-item home-banner-height"
            data-bs-interval={7000}
          >
            <img
              src="images/forklift.png"
              className="d-block img-fluid obj-fit-cover  w-100 home-banner-height"
              alt="..."
            />
          </div>
          <div
            className="carousel-item home-banner-height"
            data-bs-interval={7000}
          >
            <img
              src="images/medical.png"
              className="d-block img-fluid obj-fit-cover  w-100 home-banner-height"
              alt="..."
            />
          </div>
        </div>
      </div>
      {/* Industries banner ends here  */}
      <div className="carousel-caption  custom-carousel-caption d-flex position-absolute">
        <h1 className="carousel-head">Solutions For Your Industry</h1>
        <p className="banner-description font-regular">
          <span className="d-block my-3 font-16">
            Canon Financial Services provides financing for all Canon products and
            also a wide variety of other equipment types including software.
            Working across many different industries has given us the experience
            to develop systems and processes that make it quick and easy for
            business customers to acquire new or used equipment.
          </span>
          <span className="d-block my-4 font-16">
            CFS partners with manufacturers, distributors, and dealers to provide
            access to capital.
          </span>
        </p>
      </div>
    </div>
    <div className="container-fluid industries-card-section">
      <div className="container">
        {/* breadcrumbs start */}
        <nav aria-label="breadcrumb" className="pt-4 mb-5">
          <ol className="breadcrumb font-regular font-12 ">
            <li className="breadcrumb-item">
              <a href="#" className="primary-color text-decoration-none">
                About
              </a>
            </li>
            <li
              className="breadcrumb-item active primary-color"
              aria-current="page"
            >
              Industries
            </li>
          </ol>
        </nav>
        {/* breadcrumbs end */}
      </div>
      <div className="container pb-5 mb-2">
        <div className="industry-sd pb-4 mb-4 text-center d-flex justify-content-center flex-wrap">
          <h2 className="grey-color-v1 w-100">INDUSTRIES WE SERVE</h2>
          <p className='font-regular font-18 word-wrap mt-3'>CFS serves all these industries and many more! Are you looking for your specific industry? We can help! Please contact us for more information.</p>
          <span className='d-inline-block w-100 mt-4'>
            <a type="submit" href="mailto:contact@cfs.canon.com?subject=Canon Financial Services Website Inquiry" className="header-button px-5 text-uppercase btn-sm w-auto text-decoration-none">
              Contact Us
            </a>
          </span>
        </div>
        <div className="row justify-content-center industry-card-wrapper gap-4">
          <div className="col-md-6 col-lg-4 industry-card">
            <div className="card rounded-0" style={{ width: "100%" }}>
              <div className='d-flex vertical-align-middle justify-content-center custom-image-card'>
                <img
                  src="images/printer.svg"
                  className="card-img-top rounded-0"
                  alt="Printing machine"
                />
              </div>

              <div className="card-body pt-4 pb-4">
                <h5 className="card-title font-bold font-24 grey-color-v1">
                  Office Products
                </h5>
                <p class="card-text font-18 font-regular">We can assist you when it comes time to acquire or upgrade your office equipment. CFS offers financing for office printers, scanners, document management systems, and much more.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 industry-card">
            <div className="card rounded-0" style={{ width: "100%" }}>
              <div className='d-flex vertical-align-middle justify-content-center custom-image-card'>
                <img
                  src="images/Tech-Software - industrypage.jpg"
                  className="card-img-top rounded-0"
                  alt="technology and software"
                />
              </div>
              <div className="card-body pt-4 pb-4">
                <h5 className="card-title font-bold font-24 grey-color-v1">
                  Technology and Software
                </h5>
                <p class="card-text font-18 font-regular">CFS wants you to stay on the cutting edge. From network servers to enterprise software and everything in between, we offer financing for all your technology needs.</p>

              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 industry-card">
            <div className="card rounded-0" style={{ width: "100%" }}>
              <div className='d-flex vertical-align-middle justify-content-center custom-image-card'>
                <img
                  src="images/Healthcare-industry.jpg"
                  className="card-img-top rounded-0"
                  alt="Healthcare machine"
                />
              </div>
              <div className="card-body pt-4 pb-4">
                <h5 className="card-title font-bold font-24 grey-color-v1">
                  Healthcare
                </h5>
                <p class="card-text font-18 font-regular">CFS helps you acquire state-of-the-art medical equipment. We offer financing for a wide array of medical technology such as Magnetic Resonance Imaging, Ultrasound, X-Ray machines and more.</p>
              </div>
            </div>
          </div>


          <div className="col-md-6 col-lg-4 industry-card">
            <div className="card rounded-0" style={{ width: "100%" }}>
              <div className='d-flex vertical-align-middle justify-content-center custom-image-card'>
                <img
                  src="images/MatHandling - industry page.jpg"
                  className="card-img-top rounded-0"
                  alt="Material Handling"
                />
              </div>
              <div className="card-body pt-4 pb-4">
                <h5 className="card-title font-bold font-24 grey-color-v1">
                  Material Handling
                </h5>
                <p class="card-text font-18 font-regular">We can finance almost anything you need for your warehouse. We help you acquire exactly what your warehouse needs such as forklifts, conveyors, pallet racks, scissor lifts, and even warehouse automation equipment.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 industry-card">
            <div className="card rounded-0" style={{ width: "100%" }}>
              <div className='d-flex vertical-align-middle justify-content-center custom-image-card'>
                <img
                  src="images/Manufacturing - industry page.jpg"
                  className="card-img-top rounded-0"
                  alt="Manufacturing"
                />
              </div>
              <div className="card-body pt-4 pb-4">
                <h5 className="card-title font-bold font-24 grey-color-v1">
                  Manufacturing
                </h5>
                <p class="card-text font-18 font-regular">If you are in the business of making things, we can help! We finance manufacturing equipment like packaging machines, welding equipment, robotics, and more. We can also finance any software that you need to keep production running smoothly. </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 industry-card">
            <div className="card rounded-0" style={{ width: "100%" }}>
              <div className='d-flex vertical-align-middle justify-content-center custom-image-card'>
                <img
                  src="images/Construction - industry page.jpg"
                  className="card-img-top rounded-0"
                  alt="Construction"
                />
              </div>
              <div className="card-body pt-4 pb-4">
                <h5 className="card-title font-bold font-24 grey-color-v1">
                  Construction
                </h5>
                <p class="card-text font-18 font-regular">Discover ways to grow your construction business. With CFS, you can finance heavy machinery like excavators, loaders, bulldozers, and more.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Industry CTA starts here  */}
    <div className="cta-banner primary-gradient-bg text-center">
      <div className="cta-custom-width">
        <p className="font-34 secondary-color font-bold pt-5">
          WE SUPPORT YOUR INDUSTRY
        </p>
        <span className="d-block mb-4 banner-description">
          CFS provides financing solutions for various different markets and
          industries. Contact us today to learn more about our programs for your
          business needs.
        </span>
        <a
          onClick={() => { navigateTo("/ContactUs") }}
          className=" text-decoration-none primary-outline-btn font-bold font-16 text-uppercase mt-2 px-4 py-2 cursor-pointer"
        >
          CONTACT US
        </a>
      </div>
    </div>
    {/* Industry CTA ends here  */}
  </>

}