
import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { rootPath } from "../constants/constant";
import { myContext } from '../App';

export function Header() {

  const headerContext = useContext(myContext);

  const updateRendering = () => {
    headerContext.setRender(!headerContext.render);
    document.getElementById("navbarSupportedContent").className = "navbar-collapse collapse"
  }

  return (
    <>
      {/* header starts here */}
      <nav id="custom-navbar" className="navbar navbar-expand-lg sticky-top bg-light padding-0"
      >
        <div className="container-fluid custom-container custom-header-bg">
          <Link className="navbar-brand" to="/" onClick={updateRendering}>
            <img className="cfs-logo" src="images/Canon-Financial-services-logo.svg" alt="CFS logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className={`nav-item position-relative custom-nav-item ${window.location.pathname === rootPath ? "active" : ""}`}>
                <Link className="nav-link cfs-nav-item" to="/" onClick={updateRendering}>
                  HOME
                </Link>
              </li>
              <li className={`nav-item dropdown position-relative custom-nav-item ${window.location.pathname === rootPath + "AboutUs" || window.location.pathname === rootPath + "Industries" ? "active" : ""}`}>
                <a
                  className="nav-link cfs-nav-item  dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  ABOUT
                </a>
                <ul className="dropdown-menu custom-dropdown header-menu">
                  <li>
                    <Link
                      className={`dropdown-item nav-dropdown-item ${window.location.pathname === rootPath + "AboutUs" ? "nav-dropdown-item-active" : ""}`}
                      to="/AboutUs"
                      onClick={updateRendering}
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="dropdown-divider custom-dropdown-divider" />
                  <li>
                    <Link
                      className={`dropdown-item nav-dropdown-item ${window.location.pathname === rootPath + "Industries" ? "nav-dropdown-item-active" : ""}`}
                      to="/Industries"
                      onClick={updateRendering}
                    >
                      Industries
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`nav-item dropdown position-relative custom-nav-item ${window.location.pathname === rootPath + "InventoryFinancing" || window.location.pathname === rootPath + "DealerFinancing" || window.location.pathname === rootPath + "CustomerFinancing" ? "active" : ""}`}>
                <a
                  className="nav-link cfs-nav-item  dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  SERVICES
                </a>
                <ul className="dropdown-menu custom-dropdown header-menu">
                  <li>
                    <Link
                      className={`dropdown-item nav-dropdown-item ${window.location.pathname === rootPath + "CustomerFinancing" ? "nav-dropdown-item-active" : ""}`}
                      to="/CustomerFinancing"
                      onClick={updateRendering}
                    >
                      Customer Financing
                    </Link>
                  </li>
                  <li className="dropdown-divider custom-dropdown-divider" />
                  <li>
                    <Link
                      className={`dropdown-item nav-dropdown-item ${window.location.pathname === rootPath + "DealerFinancing" ? "nav-dropdown-item-active" : ""}`}
                      to="/DealerFinancing"
                      onClick={updateRendering}
                    >
                      Dealer Financing
                    </Link>
                  </li>
                  <li className="dropdown-divider custom-dropdown-divider" />
                  <li>
                    <Link
                      className={`dropdown-item nav-dropdown-item ${window.location.pathname === rootPath + "InventoryFinancing" ? "nav-dropdown-item-active" : ""}`}
                      to="/InventoryFinancing"
                      onClick={updateRendering}
                    >
                      Inventory Financing
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`nav-item dropdown position-relative custom-nav-item ${window.location.pathname === rootPath + "Portal" ? "active" : ""}`}>
                {/* <a
                  className="nav-link cfs-nav-item dropdown-toggle"
                  href="/Portal"
                >
                  PORTALS
                </a> */}
                <Link className="nav-link cfs-nav-item dropdown-toggle" to='/Portal' onClick={updateRendering}>PORTALS</Link>
                <ul className="dropdown-menu custom-dropdown header-menu">
                  <li>
                    <a
                      className="dropdown-item nav-dropdown-item"
                      href="https://mycfs.cfs.canon.com/"
                      target="_blank"
                    >
                      Customer Portal
                    </a>
                  </li>
                  <li className="dropdown-divider custom-dropdown-divider" />
                  <li>
                    <a
                      className="dropdown-item nav-dropdown-item"
                      href="https://www.cfsdealerportal.com/Dealer/DealerLogin.aspx?ReturnUrl=%2fDealer"
                      target="blank"
                    >
                      Dealer Portal
                    </a>
                  </li>
                </ul>
              </li>
              <li className={`nav-item position-relative custom-nav-item ${window.location.pathname === rootPath + "ContactUs" ? "active" : ""}`}>
                <Link
                  className="nav-link cfs-nav-item text-nowrap"
                  to="/ContactUs"
                  onClick={updateRendering}
                >
                  CONTACT US
                </Link>
              </li>
              <li className={`nav-item position-relative custom-nav-item ${window.location.pathname === rootPath + "Careers" ? "active" : ""}`}>
                {/* <a className="nav-link cfs-nav-item" href="/Careers"> */}
                <Link className="nav-link cfs-nav-item" to='/Careers' onClick={updateRendering}>CAREERS</Link>

                {/* </a> */}
              </li>
            </ul>
            <a
              className="header-button ms-auto text-nowrap text-decoration-none mb-4 mb-lg-0"
              type="button"
              href="https://mycfs.cfs.canon.com/"
              target="_blank"
            >
              MAKE A PAYMENT
            </a>
          </div>
        </div>
      </nav>
      {/* header ends  here */}
    </>

  );
}