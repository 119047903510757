import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router';
import { myContext } from '../App';

export function Faq() {

    const [isAllCollapsed, setIsAllCollapsed] = useState(true);

    let question1 = useRef();
    let question2 = useRef();
    let question3 = useRef();
    let question4 = useRef();
    let question5 = useRef();
    let question6 = useRef();
    let question7 = useRef();

    // use useNavigate hook to redirect the user to any other page
    const navigate = useNavigate();
    // Import and use the useContext hook to access the data from app.jsx
    const footerContext = useContext(myContext);

    const navigateTo = (path) => {
        footerContext.setRender(!footerContext.render);
        navigate(path)
    }


    function handleButtonClick() {
        setIsAllCollapsed(!isAllCollapsed);

        if (isAllCollapsed) {
            question1.children[0].classList.value += " collapsed";
            question2.children[0].classList.value += " collapsed";
            question3.children[0].classList.value += " collapsed";
            question4.children[0].classList.value += " collapsed";
            question5.children[0].classList.value += " collapsed";
            question6.children[0].classList.value += " collapsed";
            question7.children[0].classList.value += " collapsed";

            question1.children[1].classList.value += " show";
            question2.children[1].classList.value += " show";
            question3.children[1].classList.value += " show";
            question4.children[1].classList.value += " show";
            question5.children[1].classList.value += " show";
            question6.children[1].classList.value += " show";
            question7.children[1].classList.value += " show";

            question1.children[0].children[1].classList.value = "";
            question2.children[0].children[1].classList.value = "";
            question3.children[0].children[1].classList.value = "";
            question4.children[0].children[1].classList.value = "";
            question5.children[0].children[1].classList.value = "";
            question6.children[0].children[1].classList.value = "";
            question7.children[0].children[1].classList.value = "";

        } else {
            question1.children[0].classList.value = question1.children[0].classList.value.replace(/ collapsed/ig, "");
            question2.children[0].classList.value = question2.children[0].classList.value.replace(/ collapsed/ig, "");
            question3.children[0].classList.value = question3.children[0].classList.value.replace(/ collapsed/ig, "");
            question4.children[0].classList.value = question4.children[0].classList.value.replace(/ collapsed/ig, "");
            question5.children[0].classList.value = question5.children[0].classList.value.replace(/ collapsed/ig, "");
            question6.children[0].classList.value = question6.children[0].classList.value.replace(/ collapsed/ig, "");
            question7.children[0].classList.value = question7.children[0].classList.value.replace(/ collapsed/ig, "");

            question1.children[1].classList.value = question1.children[1].classList.value.replace(/ show/ig, "");
            question2.children[1].classList.value = question2.children[1].classList.value.replace(/ show/ig, "");
            question3.children[1].classList.value = question3.children[1].classList.value.replace(/ show/ig, "");
            question4.children[1].classList.value = question4.children[1].classList.value.replace(/ show/ig, "");
            question5.children[1].classList.value = question5.children[1].classList.value.replace(/ show/ig, "");
            question6.children[1].classList.value = question6.children[1].classList.value.replace(/ show/ig, "");
            question7.children[1].classList.value = question7.children[1].classList.value.replace(/ show/ig, "");

            question1.children[0].children[1].classList.value = "rotate";
            question2.children[0].children[1].classList.value = "rotate";
            question3.children[0].children[1].classList.value = "rotate";
            question4.children[0].children[1].classList.value = "rotate";
            question5.children[0].children[1].classList.value = "rotate";
            question6.children[0].children[1].classList.value = "rotate";
            question7.children[0].children[1].classList.value = "rotate";
        }
    }

    const checkAllCollapsed = () => {
        if (question1.children[0].children[1].classList.value == "rotate"
            && question2.children[0].children[1].classList.value == "rotate"
            && question3.children[0].children[1].classList.value == "rotate"
            && question4.children[0].children[1].classList.value == "rotate"
            && question5.children[0].children[1].classList.value == "rotate"
            && question6.children[0].children[1].classList.value == "rotate"
            && question7.children[0].children[1].classList.value == "rotate") setIsAllCollapsed(true);
        else if (question1.children[0].children[1].classList.value == ""
            && question2.children[0].children[1].classList.value == ""
            && question3.children[0].children[1].classList.value == ""
            && question4.children[0].children[1].classList.value == ""
            && question5.children[0].children[1].classList.value == ""
            && question6.children[0].children[1].classList.value == ""
            && question7.children[0].children[1].classList.value == "") setIsAllCollapsed(false)
    }


    return <div>
        {/* Faq banner starts */}
        <div className="position-relative align-self-center">
            <img className="object-fit-cover w-100 banner-height" src="./images/FAQ banner image.jpg" />
            <div className=" position-absolute top-50 translate-middle-y banner-text-career text-center">
                <h1 className="font-60">Frequently Asked
                    <span className="d-block">Questions</span>
                </h1>
            </div>
        </div>
        {/* Faq banner Ends */}
        {/* Faq Accordion starts */}
        <div id="accordion" className="container mt-5 mb-5 pb-3 pt-3 px-5 px-lg-0">
            <div className="w-100 d-flex justify-content-end">
                <span className="cursor-pointer expand-all d-block font-16 font-bold color-navy mb-1" id="collapseBtn" onClick={handleButtonClick}>{isAllCollapsed ? "EXPAND ALL" : "COLLAPSE ALL"}</span>
            </div>
            {/* question1 starts  */}
            <div className="py-4 border-top border-bottom" ref={el => (question1 = el)}>
                <p className="d-flex justify-content-between align-items-center cursor-pointer font-bold font-20 custom-acc collapsed-para mb-0 banner-text" id="question" data-bs-toggle="collapse" data-bs-target="#question1" onClick={() => {
                    question1.children[0].children[1].classList.value == "rotate" ? question1.children[0].children[1].classList.value = question1.children[0].children[1].classList.value.replace(/rotate/ig, "") : question1.children[0].children[1].classList.value = "rotate";
                    checkAllCollapsed();
                }} >
                    <span>How is Canon Financial Services related to Canon?</span><img id='' className="" src="./images/custom-accordion-arrow.svg" alt="accordion-arrow" />
                </p>
                <div className="collapse collapse-content show" id="question1">
                    <p className="font-16 font-regular mb-0 mt-3">
                        Canon Financial Services (CFS) is a wholly owned subsidiary of Canon USA. We offer financing for Business to
                        Business (B2B) customers which includes commercial businesses and State and Local government municipal
                        entities.
                    </p>
                </div>
            </div>
            {/* question1 ends  */}
            {/* question2 starts  */}
            <div className="py-4 border-top border-bottom" ref={el => (question2 = el)}>
                <p className="d-flex justify-content-between align-items-center cursor-pointer font-bold font-20 custom-acc collapsed-para collapsed mb-0 banner-text" data-bs-toggle="collapse" data-bs-target="#question2" onClick={() => {
                    question2.children[0].children[1].classList.value == "rotate" ? question2.children[0].children[1].classList.value = question2.children[0].children[1].classList.value.replace(/rotate/ig, "") : question2.children[0].children[1].classList.value = "rotate";
                    checkAllCollapsed();
                }}>
                    <span>How do I make a payment?</span><img className="rotate" src="./images/custom-accordion-arrow.svg" alt="accordion-arrow" />
                </p>
                <div className="collapse collapse-content" id="question2">
                    <p className="font-16 font-regular mb-0 mt-3">
                        You can make a payment by going to <a href="https://mycfs.cfs.canon.com/" className='privacy-anchor-style' target='_blank'>our online portal here</a> or by calling customer service toll-free at (800)
                        220-0330. If paying by phone, please have your payment details and invoice number ready.
                    </p>
                </div>
            </div>
            {/* question2 ends  */}
            {/* question3 starts  */}
            <div className="py-4 border-top border-bottom" ref={el => (question3 = el)}>
                <p className="d-flex justify-content-between align-items-center cursor-pointer font-bold font-20 custom-acc collapsed-para collapsed mb-0 banner-text" data-bs-toggle="collapse" data-bs-target="#question3" onClick={() => {
                    question3.children[0].children[1].classList.value == "rotate" ? question3.children[0].children[1].classList.value = question3.children[0].children[1].classList.value.replace(/rotate/ig, "") : question3.children[0].children[1].classList.value = "rotate";
                    checkAllCollapsed();
                }} >
                    <span>How do I apply for financing?</span><img className="rotate" src="./images/custom-accordion-arrow.svg" alt="accordion-arrow" />
                </p>
                <div className="collapse collapse-content" id="question3">
                    <p className="font-16 font-regular mb-0 mt-3">
                        To apply for financing, you can < a href="https://customer.cfs.canon.com/ui/ca-p/vl/7TJ3P1HH" className='privacy-anchor-style' target="_blank">click here </a>to reach our online application process. Once you complete all the required information, a Canon Financial Services representative will contact you with further details and timelines.
                    </p>
                </div>
            </div>
            {/* question3 ends  */}
            {/* question4 starts  */}
            <div className="py-4 border-top border-bottom" ref={el => (question4 = el)}>
                <p className="d-flex justify-content-between align-items-center cursor-pointer font-bold font-20 custom-acc collapsed-para collapsed mb-0 banner-text" data-bs-toggle="collapse" data-bs-target="#question4" onClick={() => {
                    question4.children[0].children[1].classList.value == "rotate" ? question4.children[0].children[1].classList.value = question4.children[0].children[1].classList.value.replace(/rotate/ig, "") : question4.children[0].children[1].classList.value = "rotate";
                    checkAllCollapsed();
                }} >
                    <span>What types of financing options are available?</span><img className="rotate" src="./images/custom-accordion-arrow.svg" alt="accordion-arrow" />
                </p>
                <div className="collapse collapse-content" id="question4">
                    <p className="font-16 font-regular mb-0 mt-3">
                        We offer equipment financing for customers. This includes new equipment, systems, and software. Please <a onClick={() => { navigateTo("/CustomerFinancing") }} className='privacy-anchor-style cursor-pointer' >visit
                            our customer financing page</a> for more information.
                        For dealers, we offer inventory financing options so you can fill your warehouse or process that large order.
                        Please <a onClick={() => { navigateTo("/InventoryFinancing") }} className='privacy-anchor-style cursor-pointer'>visit our inventory financing page</a> for more information.
                    </p>
                </div>
            </div>
            {/* question4 ends  */}
            {/* question5 starts  */}
            <div className="py-4 border-top border-bottom" ref={el => (question5 = el)}>
                <p className="d-flex justify-content-between align-items-center cursor-pointer font-bold font-20 custom-acc collapsed-para collapsed mb-0 banner-text" data-bs-toggle="collapse" data-bs-target="#question5" onClick={() => {
                    question5.children[0].children[1].classList.value == "rotate" ? question5.children[0].children[1].classList.value = question5.children[0].children[1].classList.value.replace(/rotate/ig, "") : question5.children[0].children[1].classList.value = "rotate"
                    checkAllCollapsed()
                }}>
                    <span>What equipment types can I finance through Canon Financial Services?</span><img className="rotate" src="./images/custom-accordion-arrow.svg" alt="accordion-arrow" />
                </p>
                <div className="collapse collapse-content" id="question5">
                    <p className="font-16 font-regular mb-0 mt-3">
                        We offer financing for a wide variety of equipment for manufacturers, dealers, distributors, and customers.
                        This includes, but is not limited to medical devices, copiers, printers, software, industrial equipment, and
                        so much more. Please <a onClick={() => { navigateTo("/Industries") }} className='privacy-anchor-style cursor-pointer'>visit our industries page </a>for more information.
                    </p>
                </div>
            </div>
            {/* question5 ends  */}
            {/* question6 starts  */}
            <div className="py-4 border-top border-bottom" ref={el => (question6 = el)}>
                <p className="d-flex justify-content-between align-items-center cursor-pointer font-bold font-20 custom-acc collapsed-para collapsed mb-0 banner-text" data-bs-toggle="collapse" data-bs-target="#question6" onClick={() => {
                    question6.children[0].children[1].classList.value == "rotate" ? question6.children[0].children[1].classList.value = question6.children[0].children[1].classList.value.replace(/rotate/ig, "") : question6.children[0].children[1].classList.value = "rotate"
                    checkAllCollapsed()
                }}>
                    <span>How do I get in touch with someone to assist with Inventory Finance?</span><img className="rotate" src="./images/custom-accordion-arrow.svg" alt="accordion-arrow" />
                </p>
                <div className="collapse collapse-content" id="question6">
                    <p className="font-16 font-regular mb-0 mt-3">
                        If you are interested in our inventory financing services, please reach out to our representatives by emailing <a href="mailto:InventoryFinance@cfs.canon.com" className='privacy-anchor-style'>InventoryFinance@cfs.canon.com </a> or by calling (888) 220-1636. Additionally, you can find more information about
                        our Inventory finance services on <a onClick={() => { navigateTo("/InventoryFinancing") }} className='privacy-anchor-style cursor-pointer'>our inventory financing page</a>.
                    </p>
                </div>
            </div>
            {/* question6 ends  */}
            {/* question7 starts  */}
            <div className="py-4 border-top border-bottom" ref={el => (question7 = el)}>
                <p className="d-flex justify-content-between align-items-center cursor-pointer font-bold font-20 custom-acc collapsed-para collapsed mb-0 banner-text" data-bs-toggle="collapse" data-bs-target="#question7" onClick={() => {
                    question7.children[0].children[1].classList.value == "rotate" ? question7.children[0].children[1].classList.value = question7.children[0].children[1].classList.value.replace(/rotate/ig, "") : question7.children[0].children[1].classList.value = "rotate"
                    checkAllCollapsed()
                }} >
                    <span>How will I receive my invoices?</span><img className="rotate" src="./images/custom-accordion-arrow.svg" alt="accordion-arrow" />
                </p>
                <div className="collapse collapse-content" id="question7">
                    <p className="font-16 font-regular mb-0 mt-3">
                        You will receive your invoices in one of two ways. You can select which way by accessing the online customer
                        portal.
                        If you select electronic invoicing, we will send you your monthly invoice via email. Otherwise, you will
                        receive your invoice via standard mail invoicing.
                    </p>
                </div>
            </div>
            {/* question7 ends  */}
        </div>
        {/* Faq Accordion Ends */}
    </div>
}