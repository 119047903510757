import React, { useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router';
import { myContext } from '../App';

export function DealerFinancing() {
  const buttonRef = useRef(null);

  useEffect(() => {
    buttonRef.current.click();
  }, []);

  // use useNavigate hook to redirect the user to any other page
  const navigate = useNavigate();
  // Import and use the useContext hook to access the data from app.jsx
  const footerContext = useContext(myContext);

  const navigateTo = (path) => {
    footerContext.setRender(!footerContext.render);
    navigate(path)
  }

  return <>
    {/* Industries banner starts here  */}
    <div className="position-relative">
      <div
        id="homeBanner"
        className="carousel slide home-banner-height mb-1"
        data-bs-ride="carousel"
        data-bs-pause="false"
      >
        <div className="carousel-indicators custom-carousel-indicators custom-carousel-indicators-industries">
          <button
            type="button"
            data-bs-target="#homeBanner"
            data-bs-slide-to={0}
            className="active"
            aria-current="true"
            aria-label="Slide 1"
            ref={buttonRef}
          />
          <button
            type="button"
            data-bs-target="#homeBanner"
            data-bs-slide-to={1}
            aria-label="Slide 2"
          />
          <button
            type="button"
            data-bs-target="#homeBanner"
            data-bs-slide-to={2}
            aria-label="Slide 3"
          />
          <button
            type="button"
            data-bs-target="#homeBanner"
            data-bs-slide-to={3}
            aria-label="Slide 4"
          />
        </div>
        <div className="carousel-inner home-banner-height">
          <div
            className="carousel-item active home-banner-height"
            data-bs-interval={7000}
          >
            <img
              src="images/agriculture-df.svg"
              className="d-block img-fluid obj-fit-cover w-100 home-banner-height"
              alt="..."
            />
          </div>
          <div
            className="carousel-item home-banner-height"
            data-bs-interval={7000}
          >
            <img
              src="images/3d-df.svg"
              className="d-block img-fluid obj-fit-cover  w-100 home-banner-height"
              alt="..."
            />
          </div>
          <div
            className="carousel-item home-banner-height"
            data-bs-interval={7000}
          >
            <img
              src="images/welding-df.svg"
              className="d-block img-fluid obj-fit-cover  w-100 home-banner-height"
              alt="..."
            />
          </div>
          <div
            className="carousel-item home-banner-height"
            data-bs-interval={7000}
          >
            <img
              src="images/dentist-df.svg"
              className="d-block img-fluid obj-fit-cover  w-100 home-banner-height"
              alt="..."
            />
          </div>
        </div>
      </div>
      {/* Industries banner ends here  */}
      <div className="carousel-caption  custom-carousel-caption d-flex position-absolute">
        <h1 className="carousel-head">Financing for Your Industry</h1>
        <p className="banner-description">
          <span className="my-3 d-block mb-4 banner-description mt-3">
            At Canon Financial Services, our goal is to give you flexible
            financing options for all your equipment and software needs. We offer
            competitive rates, quick decision times, and technology driven
            processes to give businesses like yours access to the financing they
            need.
          </span>
          <span className="my-3 d-block mb-sm-5 banner-description">
            Whether you are a manufacturer, distributor, or equipment dealer, our
            financing solutions give you the flexibility to support all of your
            customers’ projects. From online portfolio access to our expert
            Account Executives, you will always have the resources you need to
            achieve success.
          </span>
          <a
            onClick={() => { navigateTo("/ContactUs") }}
            className="header-button text-decoration-none text-uppercase mt-2 cursor-pointer"
          >
            contact us
          </a>
        </p>
      </div>
    </div>
    {/* Left and right section */}
    <div className="container-fluid">
      <div className="container">
        {/* breadcrumbs start */}
        <nav aria-label="breadcrumb" className="pt-4 mb-5">
          <ol className="breadcrumb font-regular font-12 ">
            <li className="breadcrumb-item">
              <a href="#" className="primary-color text-decoration-none">
                Services
              </a>
            </li>
            <li
              className="breadcrumb-item active primary-color"
              aria-current="page"
            >
              Dealer Financing
            </li>
          </ol>
        </nav>
        {/* breadcrumbs end */}
      </div>

      {/* Financing Made Simple - Card Structure starts */}
      <div className="pb-4 mb-4 mx-3 mx-lg-0 text-center d-flex justify-content-center flex-wrap">
        <h2 className="grey-color-v1 w-100">FINANCING MADE SIMPLE</h2>
        <p className='font-regular font-18 word-wrap mt-3'>We pride ourselves on excellent service and a streamlined process. Partnering with CFS gives your customers access to the capital they need quickly, empowering them to be in control of their own success.</p>
      </div>
      <div className="container mt-3 mt-lg-4">
        <div className="row justify-content-center flex-lg-nowrap">
          <div className="col-lg-4 col-md-4 px-2 border-0 border-end sm-border">
            <div className="card rounded-0 border-0 align-items-center my-2 my-xl-3 core-values-card custom-card-direction">
              <img src="images/Apply-now - CF.png" className="card-img-top" alt="caring-hands" />
              <div className="card-body text-md-center text-start primary-color px-3 px-lg-4">
                <p className="font-bold font-20 mb-1 grey-color-v1">Fast Application Process</p>
                <p className="card-text text-md-center text-start">CFS offers an online application process to provide decisions in a few hours or less.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 px-2 border-0 border-end sm-border">
            <div className="card rounded-0 border-0 align-items-center my-2 my-xl-3 core-values-card sm-border custom-card-direction">
              <img src="images/Connect-with-us - CF.png" className="card-img-top" alt="caring-hands" />
              <div className="card-body text-md-center text-start flex-wrap primary-color px-3 px-lg-4">
                <p className="font-bold font-20 mb-1 grey-color-v1">Easy Documentation</p>
                <p className="card-text text-md-center text-start">Our dedicated team works with you and your customer to best meet their financial needs.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 px-2">
            <div className="card rounded-0 border-0 align-items-center my-2 my-xl-3 core-values-card custom-card-direction">
              <img src="images/Get-needed-equipment - CF.png" className="card-img-top" alt="caring-hands" />
              <div className="card-body text-md-center text-start flex-wrap primary-color px-3 px-lg-4">
                <p className="font-bold font-20 mb-1 grey-color-v1">Deliver Equipment</p>
                <p className="card-text text-md-center text-start">Your customer receives the equipment they need, and your invoice is paid.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Financing Made Simple - Card Structure ends */}

      {/* Financing designed for you starts here */}
      <div className="container-fluid number-list-section">
        <h2 className="text-center mt-5 py-md-4 color-grey-5">
          FINANCING DESIGNED FOR YOU
        </h2>
        <div className="row justify-content-center my-0 my-md-5">
          <div className="col-lg-8 px-2 mb-4 pb-2">
            <div className="row pt-3 pb-4 border-top number-list-row">
              <div className="col-1 d-flex justify-content-center align-items-center number-list-style p-0">
                1
              </div>
              <div className="col-11 mt-2 ps-lg-4">
                <p className="font-20 font-bold color-grey-5 mb-1 ">Connect</p>
                <span className="font-16 primary-color">
                  Our experienced finance team listens to your business challenges
                  and goals.
                </span>
              </div>
            </div>
            <div className="row pt-3 pb-4 border-top number-list-row">
              <div className="col-1 d-flex justify-content-center align-items-center number-list-style p-0">
                2
              </div>
              <div className="col-11 mt-2 ps-lg-4 pe-0">
                <p className="font-20 font-bold color-grey-5 mb-1  ">Partner</p>
                <span className="font-16 primary-color">
                  Integrate our leasing options into your sales process by offering
                  financing to all of your customers.
                </span>
              </div>
            </div>
            <div className="row pt-3 pb-4 border-top border-bottom number-list-row">
              <div className="col-1 d-flex justify-content-center align-items-center number-list-style p-0">
                3
              </div>
              <div className="col-11 mt-2 ps-lg-4">
                <p className="font-20 font-bold color-grey-5 mb-1 ">Succeed</p>
                <span className="font-16 primary-color">
                  We share tried and true practices to grow our relationship for
                  ongoing financial success.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Financing designed for you ends */}

      <h2 className="text-center my-5 pb-4 color-grey-5">BENEFITS OF WORKING WITH CANON
      </h2>
      {/* Our Core Values start */}
      <div className="row d-flex justify-content-center">
        <div className="row justify-content-center flex-lg-nowrap">
          <div className="col-lg-3 px-2 mb-0 mb-md-4 pb-2">
            <div className="card rounded-0 border-top-0 border-bottom-0 border-start-0 px-4 pe-md-4 pb-3 align-items-center my-4 core-values-card core-values-card-border core-values-card-border-mobile">
              <img
                src="images/competitive-rates.svg"
                className="card-img-top"
                alt="caring-hands"
              />
              <div className="card-body d-flex justify-content-center flex-wrap primary-color px-xl-5 mx-xl-4">
                <span className="font-bold font-20 mb-1 text-center color-grey-5">
                  Competitive

                  Rates
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-2 mb-0 mb-md-4 pb-2">
            <div className="card rounded-0 border-top-0 border-bottom-0 border-start-0 px-4 pe-md-4 pb-3 align-items-center my-4 core-values-card core-values-card-border core-values-card-border-mobile">
              <img
                src="images/funding.svg"
                className="card-img-top"
                alt="caring-hands"
              />
              <div className="card-body d-flex justify-content-center flex-wrap primary-color px-xl-4 mx-xl-4">
                <span className="font-bold font-20 mb-1 text-center color-grey-5">
                  Available Same Day Funding
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-2 mb-0 mb-md-4 pb-2">
            <div className="card rounded-0 border-top-0 border-bottom-0 border-start-0 border-end-0 px-4 pe-md-4 pb-3 align-items-center my-4 core-values-card core-values-card-border-mobile">
              <img
                src="images/fast-turnaround.svg"
                className="card-img-top"
                alt="caring-hands"
              />
              <div className="card-body d-flex justify-content-center flex-wrap primary-color px-xl-4 mx-xl-4">
                <span className="font-bold font-20 mb-1 text-center color-grey-5">
                  Fast Turnaround Times
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center flex-lg-nowrap">
          <div className="col-lg-3 px-2 mb-0 mb-md-4 pb-2">
            <div className="card rounded-0 border-top-0 border-bottom-0 border-start-0 px-4 pe-md-4 pb-3 align-items-center my-4 core-values-card core-values-card-border core-values-card-border-mobile">
              <img
                src="images/online-portal.svg"
                className="card-img-top"
                alt="caring-hands"
              />
              <div className="card-body d-flex justify-content-center flex-wrap primary-color px-xl-5 mx-xl-4">
                <span className="font-bold font-20 mb-1 text-center color-grey-5">
                  Online Portal Access
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-2 mb-0 mb-md-4 pb-2">
            <div className="card rounded-0 border-top-0 border-bottom-0 border-start-0 px-4 pe-md-4 pb-3  align-items-center my-4 core-values-card core-values-card-border core-values-card-border-mobile">
              <img
                src="images/easy-application.svg"
                className="card-img-top"
                alt="caring-hands"
              />
              <div className="card-body d-flex justify-content-center flex-wrap primary-color px-xl-4 mx-xl-4 px-lg-2">
                <span className="font-bold font-20 mb-1 text-center color-grey-5">
                  Easy Application Process
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-2 mb-0 mb-md-4 pb-2">
            <div className="card rounded-0 border-top-0 border-bottom-0 border-start-0 border-end-0 px-4 pe-md-4 pb-3 align-items-center my-4 core-values-card mb-0">
              <img
                src="images/professionals.svg"
                className="card-img-top"
                alt="caring-hands"
              />
              <div className="card-body d-flex justify-content-center flex-wrap primary-color px-5 mx-4">
                <span className="font-bold font-20 mb-1 text-center color-grey-5">
                  Experienced Professionals
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Our Core Values end */}

    {/* Financing made simple starts here  */}
    <div className="container-fluid">
      <div className="row grey-bg mt-4 align-items-center">
        <div className="col-md-6 order-2 order-md-1">
          <div className="left-c-and-right-i-sec-spacing">
            <div className="container">
              <div className="mb-4 mt-4">
                <h2 className="mb-0 primary-color">Financing Made Simple</h2>
                <span className="color-grey-5 font-24 font-bold second-heading">
                  Be prepared with the tools you need
                </span>
              </div>
              <p className="font-20 font-regular custom-sm-p-sec-font">
                <span className="d-block mb-4">
                  At Canon Financial Services, your success is important to us. We
                  offer educational webinars for salespeople and simple
                  documentation so customers can get the equipment they need
                  quickly.
                </span>
                <span className="d-block mb-4">
                  {" "}
                  We are here for you every step of the way. Let us help you grow
                  your business!
                </span>
                <a
                  onClick={() => { navigateTo("/ContactUs") }}
                  className="header-button text-decoration-none full-width-sm cursor-pointer"
                >
                  CONTACT US
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 px-0 order-1 order-md-1">
          <img
            src="images/financing-simple.png"
            className="section-img w-100 custom-img-size"
            alt="section-img"
          />
        </div>
      </div>
    </div>
    {/* Financing made simple ends here */}

    {/*testimonial carousel Starts here*/}
    <div className="container-fluid p-0">
      <div className="testimonial-gradient-bg pt-3 pb-5 py-xl-4">
        <h1 className="text-center pt-5 pb-4 pb-xl-5 color-white font-34">
          WHAT OUR FANS SAY
        </h1>
        <div
          id="carouselExampleIndicators"
          className="carousel slide mb-xl-4"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner d-flex align-items-center">
            <div className="carousel-item active">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-7 mb-4 pb-2 d-flex justify-content-center">
                  <div className="row testimonial-row">
                    <div className="rounded-4 d-flex border-0 testimonial-section p-4 custom-mobile-col-direction ">
                      <div className="col-12 mt-2 text-center px-md-3">
                        <p className='font-28 font-bold'>"Our team at CFS always goes the extra mile"</p>
                        <p className="font-18 font-regular mb-4  testimonial-para-font">
                          We would like to extend a warm thank you to Ralph, and the rest of the CFS team that supports us!  Over the last few years, we have really ramped up our leasing portfolio with CFS due to the great support and dedication we get from everyone at CFS.  In these times, keeping customers happy and free of issues has become increasingly more of a challenge.
                        </p>
                        <p className="font-18 font-regular mb-4 testimonial-para-font carousel-border-bottom pb-2">
                          The team at CFS always goes the extra mile to ensure any issues are resolved quickly. Our customers and our team really appreciate and count on this level of customer service!
                        </p>
                        <span className="font-14 font-regular color-grey-5 mt-2 d-inline-block" >
                          <span className='font-bold'>Andy R.</span> President, Digital Technology, Northeast</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev custom-carousel-contol"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next custom-carousel-contol"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
    {/*testimonial carousel Ends here*/}

  </>

}