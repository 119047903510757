import React from 'react';

export function Careers() {

  return <>
    {/* Careers banner starts */}
    <div className="position-relative align-self-center">
      <img
        className="object-fit-cover w-100 banner-height"
        src="./images/careers-hero.jpg"
      />
      <div className=" position-absolute top-50 translate-middle-y banner-text-career text-center">
        <h1 className="font-60">
          Join the
          <span className="d-block">Canon Family</span>
        </h1>
      </div>
    </div>
    <div className="container-fluid">
      <div className="container divider-career">
        <h2 className="mt-5 mb-4 pb-2 text-center grey-color-v1 text-uppercase font-34">
          New Opportunities
        </h2>
        <span className="font-16">
          Working at Canon Financial Services is more than just a job. It means
          you are part of a team that promotes positive social impacts,
          professional growth, and rich innovations.
          <span className="d-block mt-3">
            We follow our philosophy of{" "}
            <span className="custom-career-tooltip">
              <span className="font-bold blue-gradient text-decoration-none">
                Kyosei
              </span>
              <span className="custom-career-tooltip-content">
                <span className="d-block">
                  Canon's corporate philosophy is the concept of Kyosei or the
                  spirit of cooperation. As a combination of the Japanese words
                  kyō (共, together) and sei (生, life), it represents a sense of
                  duty and togetherness that guides us.
                  <br />
                  <br />
                  We define Kyosei as our dedication to seeing all people,
                  regardless of culture, customs, language or race, harmoniously
                  living and working together in happiness into the future.
                </span>
              </span>
            </span>
            , and strive to ensure our greatest assets, our employees, are provided
            with:
          </span>
        </span>
        <div className="row my-5">
          <div className="col-12 col-md-4 col-lg-4 ">
            <ul className="cfs-list cfs-careers-list m-0">
              <li className="font-16 font-bold my-lg-4 my-md-4 my-2 d-flex align-items-center">
                <span className="check-mark">
                  <img src="./images/ic_success.svg" />
                </span>
                Hybrid work scheduling
              </li>
              <li className="font-16 font-bold my-lg-4 my-md-4 my-3 d-flex align-items-center">
                <span className="check-mark">
                  <img src="./images/ic_success.svg" />
                </span>
                Wide range of insurance options
              </li>
              <li className="font-16 font-bold my-lg-4 my-md-4 my-2 d-flex align-items-center">
                <span className="check-mark">
                  <img src="./images/ic_success.svg" />
                </span>
                Employee wellness programs
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-4 col-lg-4 ">
            <ul className="cfs-list cfs-careers-list m-0">
              <ul className="cfs-list">
                <li className="font-16 font-bold my-lg-4 my-md-4 my-2 d-flex align-items-center">
                  <span className="check-mark">
                    <img src="./images/ic_success.svg" />
                  </span>
                  401(k) matching
                </li>
                <li className="font-16 font-bold my-lg-4 my-md-4 my-3 d-flex align-items-center">
                  <span className="check-mark">
                    <img src="./images/ic_success.svg" />
                  </span>
                  Paid holidays and vacation
                </li>
                <li className="font-16 font-bold my-lg-4 my-md-4 my-2 d-flex align-items-center">
                  <span className="check-mark">
                    <img src="./images/ic_success.svg" />
                  </span>
                  Profit sharing
                </li>
              </ul>
            </ul>
          </div>
          <div className="col-12 col-md-4 col-lg-4 ">
            <ul className="cfs-list cfs-careers-list m-0">
              <li className="font-16 font-bold my-lg-4 my-md-4 my-2 d-flex align-items-center">
                <span className="check-mark">
                  <img src="./images/ic_success.svg" />
                </span>
                Educational assistance
              </li>
              <li className="font-16 font-bold my-lg-4 my-md-4 my-3 d-flex align-items-center">
                <span className="check-mark">
                  <img src="./images/ic_success.svg" />
                </span>
                Commuter assistance
              </li>
              <li className="font-16 font-bold my-lg-4 my-md-4 my-2 d-flex align-items-center">
                <span className="check-mark">
                  <img src="./images/ic_success.svg" />
                </span>
                And more!
              </li>
            </ul>
          </div>
          <div className="text-center mt-5 mb-4">
            <a
              href="https://external-canoncareers.icims.com/jobs"
              target="_blank"
              className="header-button text-decoration-none font-15"
            >
              VIEW CURRENT OPPORTUNITIES
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="container-lg custom-sm-container-spacing">
      <h2 className="mt-5 mb-4 pb-2 text-center text-uppercase font-34">
        Awards and Recognitions
      </h2>
      <p className="font-16">
        Canon Financial Services (CFS) believes that employees from different
        backgrounds, and demographic groups bring unique skills and perspectives.
        That’s why we constantly enhance our efforts to create a diverse and
        inclusive work environment at all levels within Canon.
        <span className="d-block mt-3">
          Our philosophy of Kyosei continues to preserve and promote a work
          environment that our employees take with them to make the community a
          better place.
        </span>
      </p>
      <div className="row my-5 px-md-3 px-sm-3">
        <div className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center my-3">
          <div className="card grey-bg border-0 card-career">
            <a href="https://www.usa.canon.com/newsroom/2023/20230216-forbes#:~:text=MELVILLE%2C%20NY%2C%20February%2016%2C%202023%20%E2%80%93%20Canon%20U.S.A.%2C,list%20is%20now%20live%20on%20the%20Forbes%20website"
              target="_blank"><img
                src="./images/logo-forbes.png"
                className="card-img-top"
                alt="..."
              /></a>

            <div className="card-body">
              <p className="card-text font-16 text-center primary-color">
                Canon U.S.A., Inc. Recognized by Forbes as One of America's Best
                Midsize Employers in 2023.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center my-3">
          <div className="card grey-bg border-0 card-career">
            <a href="https://global.canon/en/news/2022/20220418.html" target="_blank"><img
              src="./images/iF_DesignAward2023_red 1-min.jpg"
              className="card-img-top"
              alt="..."
            /></a>

            <div className="card-body">
              <p className="card-text font-16 text-center primary-color">
                Canon Designs Recognized with Internationally Renowned iF Design
                Awards for 29th Consecutive Year.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 col-lg-4 d-flex justify-content-md-center my-3">
          <div className="card grey-bg border-0 card-career">
            <a href="https://www.usa.canon.com/newsroom/2022/20221116-forbes#:~:text=MELVILLE%2C%20NY%2C%20November%2016%2C%202022%20%E2%80%93%20Canon%20U.S.A.%2C,in%20this%20prestigious%20ranking%20of%20companies%20this%20month" target='_blank'>
              <img
                src="./images/best-employees-img.jpg"
                className="card-img-top"
                alt="..."
              />
            </a>

            <div className="card-body">
              <p className="card-text font-16 text-center primary-color">
                Canon U.S.A., Inc. Recognized by Forbes as One of America's Best
                Employers for Veterans in 2022.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* gradient banner starts  */}
    <div className="primary-gradient-bg text-center py-5">
      <h3 className="font-48 text-center secondary-color mb-4">Join #TEAMCANON</h3>
      <a className="primary-outline-btn font-bold text-uppercase text-decoration-none py-2 px-3 font-15" href="https://external-canoncareers.icims.com/jobs" target="_blank">
      View Current Opportunities</a>
    </div>
    {/* gradient banner ends  */}
  </>

}