import React from 'react';

export function CustomerFinancing() {

    return <div>
        <div className="container-fluid position-relative px-0 industry-banner">
            <div className>
                <img src="images/customer-financing-hero.jpg" className="img-fluid w-100 object-fit-cover" alt="banner" />
            </div>
            <div className="container text-center banner-content-overlap position-absolute top-50 start-50 translate-middle">
                <h1 className="heading-secondary-size">Customer Financing Made Easy For You</h1>
                <p className="mb-4 banner-description mt-3">Business owners utilize many strategies to help their businesses stay on
                    track and prosper. Our experienced team can offer flexible financing solutions to support your business needs
                    for new equipment, systems, and software.
                </p>
                <p className="mb-5 banner-description">We strive to help you achieve your goals so you can take your business to the
                    next level.</p>
                <a href="https://customer.cfs.canon.com/ui/ca-p/vl/7TJ3P1HH" className="header-button text-decoration-none text-uppercase mt-2" target='_blank'>apply now</a>
            </div>
        </div>
        {/* Our Core Values start */}
        <div className="container container mb-4 pb-3">
            {/* breadcrumbs start */}
            <nav aria-label="breadcrumb" className="pt-4 mb-5">
                <ol className="breadcrumb font-regular font-12 ">
                    <li className="breadcrumb-item">
                        <a href="#" className="primary-color text-decoration-none">
                            Services
                        </a>
                    </li>
                    <li
                        className="breadcrumb-item active primary-color"
                        aria-current="page"
                    >
                        Customer Financing
                    </li>
                </ol>
            </nav>
            {/* breadcrumbs end */}

            <div className="pb-4 mb-4 mx-3 mx-lg-0 text-center d-flex justify-content-center flex-wrap">
                <h2 className="grey-color-v1 w-100">CONVENIENT. FAST. AFFORDABLE.</h2>
                <p className='font-regular font-18 word-wrap mt-3'>Financing with CFS makes it easy for customers like you to get the equipment they need at an affordable payment. Contact our sales support team to learn more about Canon Financial Services.</p>
                <span className='d-inline-block w-100 mt-4'>
                    <a href="https://customer.cfs.canon.com/ui/ca-p/vl/7TJ3P1HH" className="text-uppercase mt-5 cfp-banner-btn text-decoration-none header-button" target='_blank'>Apply now</a>
                </span>
            </div>

            <div className="row d-flex justify-content-center mt-5 mt-lg-5">
                <div className="row justify-content-center flex-lg-nowrap">
                    <div className="col-lg-4 col-md-4 px-2 mb-0 mb-md-0 pb-2">
                        <div className="card rounded-0 border-0 border-end align-items-center my-2 my-md-4 core-values-card sm-border custom-card-direction">
                            <img src="images/Apply-now - CF.png" className="card-img-top" alt="apply-now" />
                            <div className="card-body text-md-center text-start primary-color px-4">
                                <p className="font-bold font-20 mb-1 grey-color-v1">Apply</p>
                                <p className="card-text text-md-center text-start">CFS offers an online application process so you’ll get a decision in a few hours or less.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 px-2 mb-0 mb-md-0 pb-2">
                        <div className="card rounded-0 border-0 border-end align-items-center my-2 my-md-4 core-values-card sm-border custom-card-direction">
                            <img src="images/Connect-with-us - CF.png" className="card-img-top" alt="connect" />
                            <div className="card-body text-md-center text-start flex-wrap primary-color">
                                <p className="font-bold font-20 mb-1 grey-color-v1">Sign</p>
                                <p className="card-text text-md-center text-start">Our dedicated team works with you to understand and meet your financial needs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 px-2 mb-0 mb-md-0 pb-2">
                        <div className="card rounded-0 border-0 align-items-center my-2 my-md-4 core-values-card sm-border custom-card-direction">
                            <img src="images/Get-needed-equipment - CF.png" className="card-img-top" alt="equipment" />
                            <div className="card-body text-md-center text-start flex-wrap primary-color">
                                <p className="font-bold font-20 mb-1 grey-color-v1">Receive</p>
                                <p className="card-text text-md-center text-start">Receive the equipment you need at an affordable payment.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Our Core Values end */}

        {/*testimonial carousel Starts here*/}
        <div className="container-fluid p-0 mb-5">
            <div className="testimonial-gradient-bg mob-min-height pt-3 pt-xl-5 pb-5">
                <h1 className="text-center pt-5 pb-4 pb-xl-5 color-white font-34">
                    WHAT OUR FANS SAY
                </h1>
                <div id="carouselExampleIndicators" className="carousel slide mb-xl-4" data-bs-ride="carousel">
                    <div class="carousel-indicators custom-testimoniol-carousel-indicators ">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
                            aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                    </div>
                    <div className="carousel-inner custom-carousel-inner d-flex">
                        <div className="carousel-item active">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-7 mb-4 pb-2 d-flex justify-content-center">
                                    <div className="row testimonial-row">
                                        <div className="rounded-4 d-flex border-0 testimonial-section p-4 custom-mobile-col-direction ">
                                            <div className="col-12 mt-2 text-center px-md-3">
                                                <p className='font-28 font-bold'>"Canon Financial Services is an Important Partner"</p>
                                                <p className="font-18 font-regular mb-4 testimonial-para-font carousel-border-bottom pb-2">
                                                Canon Financial Services was an important partner in our recent expansion. The rates were competitive, and the approval process was done quickly, which allowed me to focus on keeping the installation on track.
                                                </p>
                                                <span className="font-14 font-regular color-grey-5 mt-2 d-inline-block" >
                                                    <span className='font-bold'>Joe O.</span> President, Marketing Communications, Northeast</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-7 mb-4 pb-2 d-flex justify-content-center">
                                    <div className="row testimonial-row">
                                        <div className="rounded-4 d-flex border-0 testimonial-section p-4 custom-mobile-col-direction ">
                                            <div className="col-12 mt-2 text-center px-md-3">
                                                <p className='font-28 font-bold'>"The communication with CFS team is Outstanding"</p>
                                                <p className="font-18 font-regular mb-4 testimonial-para-font carousel-border-bottom pb-2">
                                                When our company was first established a few years ago, we were having difficulty receiving timely financing. However, CFS was and continues to not only provide quick turnarounds but also excellent customer service. We have even referred CFS to other customers because of how great CFS has been to us. The communication with CFS' team is outstanding, and I look forward to growing our partnership even more.
                                                </p>
                                                <span className="font-14 font-regular color-grey-5 mt-2 d-inline-block" >
                                                    <span className='font-bold'>She Hyun Y.</span> CEO, Recreational Services, Southwest</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="carousel-control-prev custom-carousel-contol"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev"
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next custom-carousel-contol"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next"
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
        {/*testimonial carousel Ends here*/}
        {/* Left and right section */}
        <div className="container-fluid">
            <div className="pb-4 mb-4 mx-3 mx-lg-0 text-center d-flex justify-content-center flex-wrap">
                <h2 className="grey-color-v1 w-100">BENEFITS OF WORKING WITH CANON</h2>
                <p className='font-regular font-18 word-wrap mt-3'>
                    <a href='https://www.leasefoundation.org/industry-resources/horizon-report/' target='__blank' className='text-decoration-none privacy-anchor-style'>
                        Around 79% of U.S. businesses</a> - both small & Fortune 500- finance their equipment, and you should too! Work with Canon Financial Services for these amazing benefits: </p>
            </div>
            <div className="row my-4 align-items-center">
                <div className="col-md-6 px-0 order-1 order-md-1 left-right-sec-img">
                    <img src="images/Flexible-Terms-and-Options - Financing.jpg" className="section-img w-100" alt="section-img" />
                </div>
                <div className="col-md-6 order-2 order-md-1">
                    <div className="left-i-and-right-c-sec-spacing">
                        <div className="container">
                            <h2 className="mb-3 grey-color-v1">Flexible Terms and Options</h2>
                            <p className="font-20 font-regular custom-sm-p-sec-font">
                                <span className="d-block mb-4">We offer many different terms that provide you maximum flexibility. Standard term lengths range from 12-months to 63-months with longer terms available for some asset types.</span>
                                <span className="d-block mb-4">For customers interested in owning the equipment at the end of the term, we have purchase options to satisfy that requirement.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row grey-bg mb-4 align-items-center">
                <div className="col-md-6 order-2 order-md-1">
                    <div className="left-c-and-right-i-sec-spacing">
                        <div className="container">
                            <h2 className="mb-3 grey-color-v1">Technology Refresh</h2>
                            <p className="font-20 font-regular custom-sm-p-sec-font">
                                <span className="d-block mb-4">Using financing can help businesses get the latest technology and equipment
                                    without making large upfront investments.
                                    This gives you the advantage of staying competitive and improving productivity.</span>
                                <a href="https://customer.cfs.canon.com/ui/ca-p/vl/7TJ3P1HH" className="text-uppercase header-button text-decoration-none full-width-sm" target="_blank">Apply now</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 px-0 order-1 order-md-1  left-right-sec-img">
                    <img src="images/Technology-Refresh - Financing.jpg" className="section-img w-100" alt="section-img" />
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row mb-4 align-items-center">
                <div className="col-md-6 px-0 order-1 order-md-1  left-right-sec-img">
                    <img src="images/Simplify-Your-Budget - Financing.jpg" className="section-img w-100" alt="section-img" />
                </div>
                <div className="col-md-6 order-2 order-md-1">
                    <div className="left-i-and-right-c-sec-spacing">
                        <div className="container">
                            <h2 className="mb-3 grey-color-v1">Simplify Your Budget</h2>
                            <p className="font-20 font-regular custom-sm-p-sec-font">
                                <span className="d-block mb-4">Financing provides fixed payments over a specific period of time so you know
                                    exactly how much you need to budget each month.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row grey-bg mb-4 align-items-center">
                <div className="col-md-6 order-2 order-md-1">
                    <div className="left-c-and-right-i-sec-spacing">
                        <div className="container">
                            <h2 className="mb-3 grey-color-v1">Canon Connection</h2>
                            <p className="font-20 font-regular custom-sm-p-sec-font">
                                <span className="d-block mb-4">As one of the Canon Group companies, Canon Financial Services, Inc. provides
                                    competitive financing to U.S. based business customers, dealers and other manufacturers.</span>
                                <span className="d-block mb-4">Visit <a href="https://www.usa.canon.com/business" target="_blank" className='text-decoration-none privacy-anchor-style'>Canon U.S.A., Inc.</a></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 px-0 order-1 order-md-1 left-right-sec-img">
                    <img src="images/canon-connection-section.png" className="section-img w-100" alt="section-img" />
                </div>
            </div>
        </div>
    </div>
}