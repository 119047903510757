import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { myContext } from '../App';

export function TermsOfUse() {

  // use useNavigate hook to redirect the user to any other page
  const navigate = useNavigate();
  // Import and use the useContext hook to access the data from app.jsx
  const footerContext = useContext(myContext);

  const navigateTo = (path) => {
    footerContext.setRender(!footerContext.render);
    navigate(path)
  }

  return <>
    {/* Terms of use banner starts here */}
    <div className="text-banner primary-gradient-bg d-flex align-items-center justify-content-center ">
      <h1 className="font-60 text-center">TERMS AND CONDITIONS OF USE</h1>
    </div>
    {/* Terms of Use banner ends here */}
    <div className="container">
      <p className="font-16 text-center mt-5 mb-4 pb-2">
        Last Updated October 22, 2018
      </p>
      <p className="font-16">
        Canon Financial Services, Inc. is a member of the Canon family of
        companies ("CFS," “we,” “us,” or “our”). Our Terms and Conditions of Use
        (“Terms of Use”) consist of these terms, the online{" "}
        <a onClick={() => { navigateTo("/PrivacyStatement") }} className="privacy-anchor-style cursor-pointer">
          Privacy Statement
        </a>
        , and any supplemental terms or agreements (“Other Agreements,”
        collectively, the “Terms”) provided to you in connection with your use of
        this or any of the websites, social or mobile pages owned or operated by
        CFS (the “Site”). This is a legally binding contract in electronic form
        between you (“you,” “your,” and “yourself”) and CFS.
      </p>
      <p className="font-16">
        BY USING THE SITE, YOU ARE AGREEING TO BE BOUND, WITHOUT LIMITATION OR
        QUALIFICATION, TO THE TERMS. YOU MAY USE OUR SITE AS LONG AS YOU COMPLY
        WITH THE TERMS. THESE TERMS APPLY WHETHER YOU ARE ACCESSING THE SITE VIA A
        PERSONAL COMPUTER, A MOBILE DEVICE, OR ANY OTHER TECHNOLOGY OR DEVICE NOW
        KNOWN OR HEREAFTER DEVELOPED (EACH A “DEVICE”).
      </p>
      <h2 className="mt-5 mb-3 font-24">CONTENTS</h2>
      <ol className="font-bold font-16">
        <li>ELIGIBILITY</li>
        <li>CHANGES TO THE AGREEMENT</li>
        <li>ELECTRONIC COMMUNICATIONS</li>
        <li>USE OF SERVICES</li>
        <li>OUR INTELLECTUAL PROPERTY RIGHTS</li>
        <li>USERNAMES AND PASSWORDS</li>
        <li>LINKS TO OTHER WEBSITES AND PRODUCTS</li>
        <li>DISCLAIMER</li>
        <li>INDEMNIFICATION</li>
        <li>TERMINATION</li>
        <li>APPLICABLE LAWS</li>
        <li>GENERAL PROVISIONS</li>
      </ol>
      <h3 className="font-20">1.ELIGIBILITY</h3>
      <p className="font-16">
        In order to use the Site, you need to (a) be 18 or older, and (b) have the
        power to enter a binding contract with us and not be barred from doing so
        under any applicable laws in your country of residence.
        <span className="d-block">
          ***This site is not directed to children under 13 and we do not
          knowingly collect information from children under 13 without complying
          with applicable law.
        </span>
      </p>
      <h3 className="font-20 mt-5">2.CHANGES TO THE AGREEMENT</h3>
      <p className="font-16">
        Occasionally we may, in our discretion, make changes to the Site and the
        Terms of Use. Any material changes will be communicated to you 30 days
        prior to the changes are made effective. You may stop using the Site if
        you do not agree with those changes. Your acceptance of and/or continued
        use of the Site after notification of changes to this agreement will
        constitute your acceptance of such changes.
      </p>
      <h3 className="font-20 mt-5">3.ELECTRONIC COMMUNICATIONS</h3>
      <p className="font-16">
        When you use the Site, participate in a chat session, submit forms or send
        e-mails to us, you are communicating with us electronically. You consent
        to receive communications from us electronically. We will communicate with
        you by e-mail, or by posting notices on the Site. You agree that all
        agreements, notices, disclosures and other communications that we provide
        to you electronically satisfy any legal requirement that such
        communications be in writing.
      </p>
      <h3 className="font-20 mt-5">4.USE OF SITE</h3>
      <p className="font-16">
        You agree that your use of the Site will comply with applicable laws. In
        addition, you agree not to take any steps in connection with your use of
        the Site that could adversely affect CFS, including engaging in or
        attempting to engage in the following conduct:
      </p>
      <ul className="font-16">
        <li>
          {" "}
          Interrupting the operation of the Site in any manner whatsoever,
          including by imposing an unreasonable load on the Site;
        </li>
        <li>
          Deleting or revising any material or other information of any other
          user, CFS, or any third party;
        </li>
        <li>
          Harvesting or otherwise collecting information about others, including
          e-mail addresses, without their consent;
        </li>
        <li>
          Deciphering, decompiling, disassembling, or reverse-engineering any of
          the software comprising or in any way making up a part of the Site;
        </li>
        <li>
          Posting advertisements or solicitations of business other than those
          explicitly approved by CFS;
        </li>
        <li>
          Defeating, investigating, or providing information concerning methods of
          defeating security mechanisms, including by allowing another person to
          access the Site using credentials issued to you, or by falsifying,
          deleting, or concealing Internet Protocol header, email sender, or other
          identifying information;
        </li>
        <li>
          Engaging in conduct that restricts, inhibits or discourages any other
          person or entity from using or enjoying all or any portion, features or
          functions of the Site, or which, in CFS’s judgment, exposes CFS or any
          of its users, customers, or suppliers to any liability or detriment of
          any type;
        </li>
        <li>
          Post or submit any inaccurate, false, misleading or incomplete
          information, including your resume, biographical data, or employment
          information, and any information contained therein;
        </li>
        <li>
          Engaging in conduct affecting CFS adversely or reflecting negatively on
          CFS, the Site, CFS’s goodwill, name or reputation or causing duress,
          distress or discomfort to CFS or anyone else, or discouraging any person
          or entity from doing business with CFS; or
        </li>
        <li>
          Taking any other action that could endanger or cause damage to CFS,
          other users of the Site, or other third parties.
        </li>
      </ul>
      <p className="font-16">
        CFS may take any action that it deems appropriate if it determines, in its
        exclusive discretion, that you have engaged in any of these practices or
        otherwise violated these Terms of Use. Such action may include termination
        of your access to the Site or initiation of civil or criminal legal
        proceedings. Under these circumstances, CFS may also investigate your use
        of the Site and provide information about your use to law enforcement.
      </p>
      <h3 className="font-20 mt-5">5.OUR INTELLECTUAL PROPERTY RIGHTS</h3>
      <p className="font-16">
        <span className="font-bold">Copyright:</span>All materials on the Site,
        including but not limited to images, software, audio, text and video clips
        (the "Materials"), are protected by copyright under U.S. copyright law,
        international conventions and other copyright laws. All Materials are
        owned or licensed by CFS or by its third-party licensors. You cannot use
        the Materials, except as specified herein. Any unauthorized use of the
        Materials may violate, without limitation, copyright laws, trademark laws,
        the laws of privacy and publicity, and communications regulations and
        statutes. You may not change the Materials in any way or reproduce,
        publicly display, distribute or otherwise use the Materials for any public
        or commercial purpose. Any reproduction of the Materials on any other web
        site or networked computer environment for any purpose is prohibited.
      </p>
      <p className="font-16">
        <span className="font-bold">Trademarks:</span>CFS trademarks, logos, and
        service marks and those trademarks, logos, and service marks licensed to
        CFS by Canon Inc. (collectively, the "Trademarks") displayed on the Site,
        including but not limited to the CFS logo, are registered and unregistered
        marks of CFS or Canon Inc. All other trademarks, trade names, product
        names, service marks and all other non-CFS marks are the property of their
        respective owners. Nothing contained on the Site should be construed as
        granting, by implication, estoppel, or otherwise, any license or right to
        use any Trademark displayed on the Site without the written permission of
        CFS or such third party that may own other trademarks displayed on the
        Site. The absence of a product or service name or logo anywhere in the
        text of the Site does not constitute a waiver of any trademark or other
        intellectual property rights concerning that name or logo.
      </p>
      <h4 className="font-18">NOTICE OF COPYRIGHT INFRINGEMENT</h4>
      <p className="font-16">
        If you believe in good faith that your copyrighted work has been
        reproduced on or linked from the Site without authorization in a way that
        constitutes copyright infringement, please provide CFS’s designated
        copyright agent with the following information:
      </p>
      <ul className="font-16">
        <li>
          {" "}
          Identification of the copyrighted work claimed to have been infringed;
        </li>
        <li>
          {" "}
          Identification of the allegedly infringing material on the Site that is
          requested to be removed;
        </li>
        <li>
          Your name, address and daytime telephone number, and an e-mail address
          if available, so that CFS may contact you if necessary;
        </li>
        <li>
          A statement that you have a good-faith belief that the use of the
          copyrighted work is not authorized by the copyright owner, its agent, or
          the law;
        </li>
        <li>
          {" "}
          A statement that the information in the notification is accurate, and
          under penalty of perjury, that the signatory is authorized to act on
          behalf of the owner of an exclusive copyright right that is allegedly
          infringed; and
        </li>
        <li>
          An electronic or physical signature of the copyright owner or someone
          authorized on the owner's behalf to assert infringement of copyright and
          to submit the statement.
        </li>
      </ul>
      <p className="font-16">
        CFS’s copyright agent for notice of claims of infringement on the Site is:
      </p>
      <p className="font-16">
        By U.S. Mail: Canon Financial Services, Inc., 158 Gaither Drive, Suite
        200, Mount Laurel, NJ 08054.
      </p>
      <p className="font-16">By Email: customer@cfs.canon.com</p>
      <p className="font-16">
        This contact information is only for suspected copyright infringement.
        Contact information for other matters is provided elsewhere on the Site.
        Upon receipt of such a notice of claimed infringement (or any statement in
        conformance with 17 U.S.C. § 512(c)(3)), CFS will act expeditiously to
        remove or disable access to any content that is claimed to be infringing
        upon the copyright of any person under the laws of the United States, and
        will terminate the Site privileges of those who repeatedly infringe on the
        copyright of others. United States law imposes substantial penalties for
        falsely submitting a notice of copyright infringement.
      </p>
      <h3 className="font-20 mt-5">6.USERNAMES AND PASSWORDS</h3>
      <p className="font-16">
        Certain areas of the Site may offer the opportunity to register or create
        an account. You will be asked to create a username and password in
        connection with such an account. You may not create an account on behalf
        of a legal entity unless you have been authorized by that legal entity to
        do so. Accounts created on behalf of a legal entity must designate at
        least one individual as an authorized user that will have the authority to
        bind the legal entity. CFS reserves the right to contact the legal entity
        you have created an account for to verify that you have been authorized to
        create an account on its behalf. You may never use another’s account
        without permission from an authorized user. You are responsible for
        maintaining the confidentiality of the password and account and are fully
        responsible for all activities that occur under your password or account.
        CFS cannot and will not be liable for any loss or damage arising from your
        failure to comply with this Section.
      </p>
      <p className="font-16">
        If CFS requests that you provide information about yourself in connection
        with your creation of an account, you agree that the information that you
        provide will be entirely truthful and accurate. If the information that
        you provide to CFS changes, you agree to promptly provide CFS with updated
        information.
      </p>
      <h3 className="font-20 mt-5">7.LINKS TO OTHER WEBSITES AND PRODUCTS</h3>
      <p className="font-16">
        Mention of third party products, companies and web sites on the Site is
        for informational purposes only and constitutes neither an endorsement nor
        a recommendation. CFS makes no representations regarding the quality,
        safety or suitability of any products by third party companies. Likewise,
        links to third party sites are provided for your convenience only, and CFS
        is not responsible for the content of any site linked to or from the Site.
        Links from the Site to any other site do not mean that CFS approves, or
        endorses or recommends that site. CFS disclaims all warranties, express or
        implied, as to the accuracy, legality, reliability or validity of any
        content on any other site. Viewing of all other sites is at your own risk.
      </p>
      <h3 className="font-20 mt-5">8.DISCLAIMER</h3>
      <p className="font-16">
        YOUR USE OF THE SITE IS SOLELY AT YOUR OWN RISK. THE SITE (INCLUDING ALL
        CONTENT AND FUNCTIONS MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE) ARE
        PROVIDED "AS IS." TO THE FULLEST EXTENT PERMISSIBLE BY LAW, CFS MAKES NO
        REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER (1) FOR THE ACCURACY,
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF
        ANY CONTENT PUBLISHED ON OR AVAILABLE THROUGH THE SITE, (2) THAT THE
        SERVER THAT MAKES THE SITE AVAILABLE IS FREE OF VIRUSES OR OTHER
        COMPONENTS THAT MAY INFECT, HARM OR CAUSE DAMAGE TO YOUR COMPUTER
        EQUIPMENT OR ANY OTHER PROPERTY WHEN YOU ACCESS, BROWSE, DOWNLOAD FROM OR
        OTHERWISE USE THE SITE, (3) THAT CFS WILL CONTINUE TO PROVIDE THE SITE OR,
        IF IT DOES CONTINUE TO PROVIDE THE SITE, THAT CFS WILL CONTINUE TO PROVIDE
        THE SITE OR THAT THE SITE WILL FUNCTION THE WAY THAT IT CURRENTLY DOES; OR
        (4) THAT ANY OF YOUR CONTENT OR COMMUNICATIONS WILL BE RETAINED OR STORED
        ON THE SITE FOR ANY PARTICULAR AMOUNT OF TIME. CFS DISCLAIMS ANY
        RESPONSIBILITY FOR TYPOGRAPHICAL ERRORS AND ACCURACY OF THE INFORMATION
        THAT MAY BE CONTAINED ON THE SITE.
      </p>
      <p className="font-16">
        OTHER USERS OF THE SITE MAY SUBMIT CONTENT OR COMMUNICATIONS IN A WAY THAT
        IS PUBLICLY ACCESSIBLE, AND CFS CANNOT PREDICT THE CONTENT OR
        COMMUNICATIONS THAT USERS POST. CFS THEREFORE DOES NOT WARRANT OR
        REPRESENT THAT THE CONTENT ON THE SITE WILL BE APPROPRIATE FOR ANY
        PARTICULAR AUDIENCE OR THAT YOU WILL NOT BE HARMED BY VIEWING THE CONTENT
        ON THE SITE. BECAUSE ACCESSING THE SITE IS INHERENTLY RISKY, YOU ASSUME
        ALL RISKS ASSOCIATED WITH YOUR DECISION TO ACCESS IT.
      </p>
      <p className="font-16">
        UNDER NO CIRCUMSTANCES, INCLUDING BUT NOT LIMITED TO CFS’S NEGLIGENCE OR
        GROSS NEGLIGENCE, WILL CFS, OR ANY OTHER PARTY INVOLVED IN CREATING,
        HOSTING OR DELIVERING THE SITE, BE LIABLE FOR ANY DIRECT, INDIRECT,
        INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR OTHER DAMAGES WHATSOEVER
        ARISING OUT OF YOUR ACCESS TO, OR USE OF, THE SITE, EVEN IF CFS HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. PLEASE NOTE THAT SOME
        JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
        CONDITIONS, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. CHECK
        YOUR LOCAL LAWS FOR ANY RESTRICTIONS OR LIMITATIONS REGARDING THE
        EXCLUSION OF IMPLIED WARRANTIES.
      </p>
      <p className="font-16">
        CFS DISCLAIMS ALL WARRANTIES, EXPRESS AND IMPLIED, TO THE GREATEST EXTENT
        PERMITTED BY LAW. CFS ALSO ASSUMES NO RESPONSIBILITY, AND WILL NOT BE
        LIABLE FOR, ANY DAMAGE TO OR VIRUSES THAT MAY INFECT YOUR COMPUTER
        EQUIPMENT OR OTHER PROPERTY, OR FOR ANY LOSS OR CORRUPTION OF DATA ON
        ACCOUNT OF YOUR ACCESS TO, USE OF, OR BROWSING IN THE SITE, OR YOUR
        DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO, OR AUDIO FROM THE
        SITE. NOTWITHSTANDING THE LIMITATION OF LIABILITY CONTAINED HEREIN, IN NO
        EVENT SHALL CFS'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND
        CAUSES OF ACTION (WHETHER IN CONTRACT, TORT OR OTHERWISE) EXCEED THE
        AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE SITE.
      </p>
      <h3 className="font-20 mt-5">9.INDEMNIFICATION</h3>
      <p className="font-16">
        You agree to indemnify, defend and hold CFS and all of its agents,
        directors, employees, information providers, licensors and licensees,
        officers and parent, (collectively "Indemnified Parties") harmless from
        and against any and all liability and costs (including, without limitation
        attorneys' fees and costs), incurred by the Indemnified Parties in
        connection with your use of the Site or any claim in connection with or
        resulting from any breach by you of these Terms of Use. You agree to
        cooperate fully in CFS's defense of any such claim. You agree that CFS
        shall be entitled to select its own counsel, at your expense, to defend it
        in connection with any claim subject to indemnification by you. You agree
        that CFS, and not you, will have control over the defense of any such
        claim. You further agree that you will not in any event settle any matter
        involving CFS, whether or not the settlement binds or is on behalf of CFS,
        without the written consent of CFS
      </p>
      <h3 className="font-20 mt-5">10.TERMINATION</h3>
      <p className="font-16">
        CFS may terminate this agreement and/or suspend or terminate your access
        to the Site for any reason at any time without notice to you. If you wish
        to discontinue your access to the Site and cancel any account that you
        have been issued, please refer to the supplemental terms of that
        particular subsite or service.
        <span className="d-block">
          Otherwise applicable sections of the Terms of Use shall survive any
          termination of your account or this agreement. In particular, because
          the licenses you grant to us are perpetual, these licenses survive any
          termination of this agreement.
        </span>
      </p>
      <h3 className="font-20 mt-5">11.APPLICABLE LAWS</h3>
      <p className="font-16">
        The Site is administered by CFS from its offices in Mount Laurel, New
        Jersey, United States of America. The Terms are governed and interpreted
        under the laws of the State of New Jersey. You agree that any claim or
        dispute against CFS arising out of or relating to the use of the Site must
        be resolved by a court located in Burlington County or Camden County, New
        Jersey, unless otherwise agreed upon by all parties. Any claim or cause of
        action arising out of or related to your use of the Site must be filed
        within one (1) year after such claim or cause of action arose, regardless
        of any statute or law to the contrary. In the event any such claim or
        cause of action is not filed within such one (1) year period, such claim
        or cause of action shall be forever barred.
      </p>
      <h3 className="font-20 mt-5">12. GENERAL PROVISIONS</h3>
      <p className="font-16">
        You agree that there is no employment, partnership, agency, joint employer
        or joint venture relationship between you and CFS arising out of or
        resulting from your use of the Site. The Terms set forth the entire
        understanding and agreement between you and CFS with respect to the Site.
        If any provision of these Terms of Use shall be determined to be unlawful,
        void or for any reason unenforceable by a court or other legal authority
        of competent jurisdiction, then that provision will be severed from these
        Terms of Use and will be deemed replaced by an equivalent enforceable
        provision that, as nearly as possible, reflects the intent of the parties.
        The severance of any individual provision of these Terms of Use will not
        affect the validity and enforceability of any remaining provisions.
      </p>
      <p className="font-16 mt-3">
        BY CONTINUING TO USE THE SITE, YOU AGREE TO BE BOUND BY THESE TERMS OF
        USE.
      </p>
    </div>
  </>
}
