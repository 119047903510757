import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { myContext } from '../App';

export function InventoryFinancing() {
  const onContactUsClick = async () => {
    setShowSubmittedMessage(false);
  }
  // use useNavigate hook to redirect the user to any other page
  const navigate = useNavigate();
  // Import and use the useContext hook to access the data from app.jsx
  const footerContext = useContext(myContext);

  const navigateTo = (path) => {
    footerContext.setRender(!footerContext.render);
    navigate(path)
  }

  const [showSubmittedMessage, setShowSubmittedMessage] = useState(true);



  return <>
    <div className="container-fluid position-relative px-0 industry-banner if-banner">
      <div className="text-center">
      <div className>
                <img src="./images/if-banner.svg" className="img-fluid w-100 object-fit-cover" alt="banner" />
            </div>
        
        <div className='banner-content-overlap position-absolute top-50 start-50 translate-middle if-width'>
        <h1 className="heading-secondary-size mt-xl-5">
          Meet Your Inventory Needs with CFS
        </h1>
          <p className="mb-4 banner-description font-16 mt-3">
            Canon Financial Services understands that to be successful, you must
            continually navigate variable demand, supply levels and pricing. That’s
            why we provide fast funding to your distributor or manufacturer so you
            can spend more time selling.
          </p>
          <p className="mb-4 banner-description font-16">
            CFS offers best-in-class inventory financing options like 24/7 online
            access, showroom financing, lines of credit, interest-free periods up to
            150 days, and so much more.
          </p>
          <p className="mb-4 banner-description font-16">
            {" "}
            Whether you are making regular ongoing purchases to fill your warehouse
            or processing a large opportunity, CFS gives you the inventory financing
            tools so you can have the time and flexibility needed to be very
            competitive in your market.
          </p>
          <a type="submit" href="mailto:inventoryfinance@cfs.canon.com?subject=Canon Financial Services Inventory Finance Inquiry" className={`header-button px-5 text-uppercase btn-sm w-auto mb-3 text-decoration-none`} onClick={() => { onContactUsClick() }}>Contact Us</a>
          <div className="custom-form-text info-msg-margin text-center text-white" hidden={showSubmittedMessage}>
            Thanks for your interest! If your default email application did not open, please email us at <a href="mailto:contact@cfs.canon.com?subject=Customer Service Website Inquiry">contact@cfs.canon.com.</a>
          </div>
        </div>
      </div>
    </div>
    {/* Left and right section */}
    <div className="container-fluid">
      <div className="container">
        {/* breadcrumbs start */}
        <nav aria-label="breadcrumb" className="pt-4 mb-5">
          <ol className="breadcrumb font-regular font-12 ">
            <li className="breadcrumb-item">
              <a href="#" className="primary-color text-decoration-none">
                Services
              </a>
            </li>
            <li
              className="breadcrumb-item active primary-color"
              aria-current="page"
            >
              Inventory Financing
            </li>
          </ol>
        </nav>
        {/* breadcrumbs end */}
      </div>
      <h2 className="text-center my-5 py-4 mb-2 color-grey-5">
        WHY CHOOSE CFS INVENTORY FINANCING?
      </h2>
      {/* Our Core Values start */}
      <div className="row d-flex justify-content-center">
        {/* <h2 class="d-flex justify-content-center mb-4 pb-2 grey-color-v1">Our Core Values</h2> */}
        <div className="row justify-content-center flex-lg-nowrap">
          <div className="col-lg-4 px-2 mb-0 mb-md-4 pb-2">
            <div className="card rounded-0 border-top-0 border-bottom-0 border-start-0 px-4 pe-md-4 pb-3 align-items-center my-4 core-values-card core-values-card-border core-values-card-border-mobile">
              <img
                src="./images/increase liquidity.svg"
                className="card-img-top"
                alt="caring-hands"
              />
              <div className="card-body text-center flex-wrap primary-color px-2 mx-2">
                <span className="font-bold font-20 mb-1 text-center color-grey-5">
                  Increase Liquidity
                </span>
                <p className="text-center">
                  Match your sales and collection<br /> cycle to your repayment terms.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 px-2 mb-0 mb-md-4 pb-2">
            <div className="card rounded-0 border-top-0 border-bottom-0 border-start-0 border-end-0 px-4 pe-md-4 pb-3 align-items-center my-4 core-values-card core-values-card-border-mobile">
              <img
                src="./images/conserve-cas.svg"
                className="card-img-top"
                alt="caring-hands"
              />
              <div className="card-body text-center flex-wrap primary-color px-2 mx-2">
                <span className="font-bold font-20 mb-1 text-center color-grey-5">
                  Conserve Cash
                </span>
                <p className="text-center">
                  Flexible and longer terms allows <br />for increased working capital.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center flex-lg-nowrap">
          <div className="col-lg-4 px-2 mb-0 mb-md-4 pb-2">
            <div className="card rounded-0 border-top-0 border-bottom-0 border-start-0 px-4 pe-md-4 pb-3 align-items-center my-4 core-values-card core-values-card-border core-values-card-border-mobile">
              <img
                src="./images/imrprove-margins.svg"
                className="card-img-top"
                alt="caring-hands"
              />
              <div className="card-body text-center flex-wrap primary-color px-2 mx-2">
                <span className="font-bold font-20 mb-1 text-center color-grey-5">
                  Improve Margins
                </span>
                <p className="text-center">
                  Enhanced rebates when taking <br />advantage of longer repayments.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 px-2 mb-0 mb-md-4 pb-2">
            <div className="card rounded-0 border-top-0 border-bottom-0 border-start-0 border-end-0 px-4 pe-md-4 pb-3 align-items-center my-4 core-values-card mb-0">
              <img
                src="./images/competitive-rates.svg"
                className="card-img-top"
                alt="caring-hands"
              />
              <div className="card-body text-center flex-wrap primary-color px-2 mx-2">
                <span className="font-bold font-20 mb-1 text-center color-grey-5">
                  No Out-of-pocket Funds
                </span>
                <p className="text-center">
                  An approved customer lease<br /> means no cash outlay to buy needed
                  inventory.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Our Core Values end */}
    </div>
    {/* Financing made simple starts here  */}
    <div className="container-fluid">
      <div className="row grey-bg mt-4 mb-5 align-items-center">
        <div className="col-md-6 order-2 order-md-1">
          <div className="left-c-and-right-i-sec-spacing">
            <div className="container">
              <div className="mb-4 mt-4">
                <h2 className="mb-0 primary-color">
                  The Canon Financial Difference
                </h2>
                <span className="color-grey-5 font-24 font-bold second-heading ">
                  Be prepared with the tools you need
                </span>
              </div>
              <p className="font-20 font-regular custom-sm-p-sec-font">
                <span className="d-block mb-4">
                  If you are filling up your warehouse or working on a big
                  project, this type of financing is for you. We offer nationwide
                  equipment manufacturer programs for dealer financing which,
                  unlike leasing, allows dealers like you to buy quantities of
                  products while maintaining cash flow.
                </span>
                <span className="d-block mb-4">
                  We offer special promotions and programs that allow you to
                  enhance your profit margins wherever possible.
                </span>
                <span className="d-block mb-4">
                  Grow your business with us today!
                </span>
                <a type="submit" href="mailto:inventoryfinance@cfs.canon.com?subject=Canon Financial Services Inventory Finance Inquiry"
                  onClick={() => { onContactUsClick() }}
                  className="header-button text-decoration-none full-width-sm cursor-pointer"
                >
                  CONTACT US
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 px-0 order-1 order-md-1">
          <img
            src="./images/canon-financial-difference.jpg"
            className="section-img inventory-finance-section-img w-100 custom-img-size"
            alt="section-img"
          />
        </div>
      </div>
    </div>
    {/* Financing made simple ends here */}
    {/* Financing design for you starts here */}
    <div className="container-fluid number-list-section">
      <h2 className="text-center my-5 pb-3 py-md-4 mb-2 color-grey-5">
        INVENTORY FINANCE MADE SIMPLE
      </h2>
      <div className="row justify-content-center my-0 my-md-5">
        <div className="col-lg-8 px-2 mb-4 pb-2">
          <div className="row pt-3 pb-4 border-top number-list-row">
            <div className="col-1 d-flex justify-content-center align-items-center number-list-style p-0">
              1
            </div>
            <div className="col-11 mt-2 ps-lg-4">
              <p className="font-20 font-bold color-grey-5 mb-1 ">Connect</p>
              <span className="font-16 primary-color">
                Connect with our team of experts to identify your unique inventory
                finance needs.
              </span>
            </div>
          </div>
          <div className="row pt-3 pb-4 border-top number-list-row">
            <div className="col-1 d-flex justify-content-center align-items-center number-list-style p-0">
              2
            </div>
            <div className="col-11 mt-2 ps-lg-4 pe-0">
              <p className="font-20 font-bold color-grey-5 mb-1  ">Access</p>
              <span className="font-16 primary-color">
                Once approved, you can access your line of credit online and
                facilitate your business in real time.
              </span>
            </div>
          </div>
          <div className="row pt-3 pb-4 border-top border-bottom number-list-row">
            <div className="col-1 d-flex justify-content-center align-items-center number-list-style p-0">
              3
            </div>
            <div className="col-11 mt-2 ps-lg-4">
              <p className="font-20 font-bold color-grey-5 mb-1 ">Order</p>
              <span className="font-16 primary-color">
                Use your line of credit to purchase equipment for any business
                project or transaction you have.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Financing design for you ends here */}
  </>

}