import React from 'react';

export function PrivacyRights() {
    return <>
        {/* Privary right banner starts here */}
        <div className="text-banner primary-gradient-bg d-flex align-items-center justify-content-center ">
            <h1 className="font-60 text-center">PRIVACY RIGHTS</h1>
        </div>
        {/* Privary right banner ends here */}
        <div className="container">
            <h2 className="mt-5 mb-4 pb-2 grey-color-v1 text-uppercase font-20">
                YOUR PRIVACY RIGHTS, CHOICE AND ACCESS
            </h2>
            <p className="font-16">
                Please note that submission of the form accessible via the link below only
                exercises your rights with respect to personal information you have shared
                with Canon Financial Services.
            </p>
            <p className="font-16">You have the right to request us to:</p>
            <ul className="privacy-ul-style">
                <li>Withdraw your consent where you have provided it;</li>
                <li>
                    Provide you with a copy of the personal information that we have
                    collected about you, to the extent that we store or maintain that
                    information;
                </li>
                <li>
                    Not send you marketing or promotional emails or other communications;
                </li>
                <li>Correct or update your personal information;</li>
                <li>Delete your personal information;</li>
                <li>Stop processing your personal information;</li>
                <li>Not disclose your personal information to third parties;</li>
                <li>and Not contact you in the future.</li>
            </ul>
            <p className="font-16">
                If you wish to make any of the above requests (for example, if you wish to
                submit a request to no longer receive communications, including marketing
                communications, from CFS), please:
            </p>
            <p className="font-16">
                Click unsubscribe in the email that you receive from us; or E-mail us at{" "}
                <a href="mailto:privacy@cfs.canon.com" className="privacy-anchor-style">
                    privacy@cfs.canon.com{" "}
                </a>
                .
            </p>
            <p className="font-16">
                Please note that where you unsubscribe from our marketing communications,
                we will keep a record of your communication information (including email
                address) to ensure we do not market to you in future. We may also send you
                other types of transactional and relationship email communications, such
                as service announcements and administrative notices without offering you
                the opportunity to opt out of receiving them.
            </p>
            <p className="font-16">
                We are under no obligation to store your personal information indefinitely
                and disclaim any liability arising out of, or related to, the destruction
                of such personal information.
            </p>
            <p className="font-16">
                It may not always be possible to completely remove or delete all of your
                personal information from our databases without some residual data
                remaining because of backups and other reasons. Additionally, please be
                aware that we may need to retain some of your personal information in
                order to maintain our records, comply with our legal obligations, resolve
                disputes, or enforce our agreements. We may also keep a record of
                correspondence with you (for example if you have made a complaint about a
                product or our Services) to protect us from a legal claim.
            </p>
            <h2 className="mt-5 mb-4 pb-2 grey-color-v1 text-uppercase font-20">
                CALIFORNIA PRIVACY RIGHTS
            </h2>
            <p className="font-16">
                Collection, Disclosure, and Sale or Sharing of Personal Information
            </p>
            <p className="font-16">
                We collect, and in the last twelve (12) months, we have collected,
                disclosed for a business purpose and sold/shared the following categories
                of personal information:
            </p>
            <div className="table-responsive mt-5 mb-3">
                <table className="table table-bordered custom-privacy-table">
                    <thead>
                        <tr className="vertical-align-middle">
                            <th className="text-center py-2 w-20 font-16">
                                Category of Personal Information
                            </th>
                            <th className="text-center py-2 w-20 font-16">
                                Specific Personal Information Collected
                            </th>
                            <th className="text-center py-2 w-20 font-16">
                                Disclosed for Business Purpose
                            </th>
                            <th className="text-center py-2 w-20 font-16">
                                Entities that We Disclose Personal Information for a Business
                                Purpose
                            </th>
                            <th className="text-center py-2 w-20 font-16">Sold or Shared</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Personal Identifiers, such as name, email address, online
                                identifiers and other information.
                            </td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Name</li>
                                    <li>Postal address</li>
                                    <li>Phone number</li>
                                    <li>Email address</li>
                                    <li>Website</li>
                                    <li>Contact Preferences</li>
                                    <li>Phone support verification question and answer</li>
                                    <li>Nationality</li>
                                    <li>Citizenship</li>
                                    <li>Visa status</li>
                                    <li>Gender/sex</li>
                                    <li>Disability status</li>
                                    <li>Veteran’s status</li>
                                    <li>Marital status</li>
                                </ul>
                            </td>
                            <td>Yes</td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Affiliates</li>
                                    <li>Service Providers</li>
                                    <li>
                                        Other third parties when required by law or to protect Canon
                                        or other persons, as described in this Privacy Statement
                                    </li>
                                </ul>
                            </td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>
                                Payment Information, such as financial information, or payment
                                method information
                            </td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Name</li>
                                    <li>Billing address</li>
                                    <li>Credit/debit card information</li>
                                    <li>
                                        Banking information (including your account number and routing
                                        number) Yes Affiliates
                                    </li>
                                    <li>Service Providers</li>
                                    <li>
                                        Other third parties when required by law or to protect Canon
                                        or other persons, as described in this Privacy Statement
                                    </li>
                                    <li>
                                        NoProtected Classification Characteristics, such as age,
                                        ethnicity and gender.
                                    </li>
                                    <li>Nationality</li>
                                    <li>Citizenship</li>
                                    <li>Visa status</li>
                                    <li>Gender/sex,</li>
                                    <li>Disability status</li>
                                    <li>Veteran’s status</li>
                                    <li>Marital status</li>
                                </ul>
                            </td>
                            <td>Yes</td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Affiliates</li>
                                    <li>Service Providers</li>
                                    <li>
                                        Other third parties when required by law or to protect Canon
                                        or other persons, as described in this Privacy Statement
                                    </li>
                                </ul>
                            </td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>
                                Commercial Information, such as information about the equipment
                                you are leasing, and other information about your transaction or
                                lease.
                            </td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Equipment or other product and service preferences</li>
                                    <li>
                                        Equipment, products and services purchased or leased
                                        (including your lease number, equipment model number/serial
                                        number, and buyout amount)
                                    </li>
                                    <li>
                                        Inquiries about equipment, products and services purchased or
                                        leased
                                    </li>
                                    <li>Service center responses</li>
                                    <li>Customer survey responses</li>
                                    <li>Feedback from visits by our representatives</li>
                                    <li>
                                        Technical information related to your Canon products
                                        (including model, serial number, unique device identifier,
                                        diagnostic information, usage data, camera and lens settings,
                                        system logs, application configurations, consumables status,
                                        etc.);
                                    </li>
                                    <li>
                                        Details on your customer service, warranty and repairs
                                        interactions with Canon
                                    </li>
                                </ul>
                            </td>
                            <td>Yes</td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Affiliates</li>
                                    <li>Service Providers</li>
                                    <li>
                                        Other third parties when required by law or to protect Canon
                                        or other persons, as described in this Privacy Statement
                                    </li>
                                </ul>
                            </td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>
                                Internet/Network Information, such as IP address, Device
                                Information, and Log and Analytics Data.
                            </td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>IP address</li>
                                    <li>Browser type</li>
                                    <li>Browser language</li>
                                    <li>Browser history</li>
                                    <li>Access times</li>
                                    <li>Originating website (referring URL)</li>
                                    <li>Unique device identifier</li>
                                    <li>Clickstream behavior</li>
                                    <li>
                                        Information about your browsing behavior, device information
                                        and interactions (including repeated site visits,
                                        interactions, keywords, online content production, etc.)
                                    </li>
                                </ul>
                            </td>
                            <td>Yes</td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Affiliates</li>
                                    <li>Service Providers</li>
                                    <li>
                                        Other third parties when required by law or to protect Canon
                                        or other persons, as described in this Privacy Statement
                                    </li>
                                </ul>
                            </td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>
                                Geolocation Data, such as Location Information from your device or
                                estimated based on your IP address.
                            </td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>IP address</li>
                                </ul>
                            </td>
                            <td>Yes</td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Affiliates</li>
                                    <li>Service Providers</li>
                                </ul>
                            </td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>
                                Other Personal Information, such as information you post on our
                                platform or on social media pages, and information you submit to
                                us.
                            </td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Right to work documentation</li>
                                    <li>Work history</li>
                                    <li>Prior employer</li>
                                    <li>Job title</li>
                                    <li>Salary history</li>
                                    <li>Education information</li>
                                    <li>Training history</li>
                                    <li>Details of qualifications, skills and experience</li>
                                    <li>Professional licenses and memberships</li>
                                    <li>References information</li>
                                    <li>Job interest data</li>
                                    <li>
                                        Information about your personal preferences (such as whether
                                        you consent to receive marketing)
                                    </li>
                                </ul>
                            </td>
                            <td>Yes</td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Affiliates</li>
                                    <li>Service Providers</li>
                                </ul>
                            </td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>
                                Inferences, such as predictions about your interests and
                                preferences drawn to create a profile
                            </td>
                            <td>None</td>
                            <td>Not applicable</td>
                            <td>Not applicable</td>
                            <td>Not applicable</td>
                        </tr>
                        <tr>
                            <td>
                                Sensitive Personal Information, such as log-in information,
                                biometric information
                            </td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Social security number</li>
                                    <li>Driver’s license number or passport number</li>
                                    <li>Ethnicity</li>
                                    <li>Race</li>
                                    <li>Hand scan</li>
                                </ul>
                            </td>
                            <td>Yes</td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Affiliates</li>
                                    <li>Service Providers</li>
                                </ul>
                            </td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>
                                Professional or Employment Related Information, including resume,
                                references and cover letter
                            </td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Education</li>
                                    <li>Degree(s) awarded</li>
                                    <li>Employment history</li>
                                    <li>Employment records</li>
                                    <li>Professional memberships/licenses</li>
                                    <li>Credit references</li>
                                    <li>Job titles</li>
                                    <li>Disciplinary history</li>
                                    <li>Performance/evaluation information</li>
                                    <li>Grievance information</li>
                                    <li>Salary history</li>
                                    <li>Training records</li>
                                    <li>National citizenship</li>
                                </ul>
                            </td>
                            <td>Yes</td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Affiliates</li>
                                    <li>Service Providers</li>
                                </ul>
                            </td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>Health Information</td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Biological testing</li>
                                    <li>Medical conditions</li>
                                    <li>Medical or other leave information</li>
                                    <li>Disability status</li>
                                    <li>Accommodations</li>
                                </ul>
                            </td>
                            <td>Yes</td>
                            <td>
                                <ul className="privacy-ul-style">
                                    <li>Service Providers</li>
                                </ul>
                            </td>
                            <td>No</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p className="font-16 mb-4">
                CFS may disclose your personal information to service providers that
                support our businesses and perform services on our behalf, such as
                companies that process payments for us or that deliver email marketing
                messages that you have requested from us. However, CFS does not sell or
                share your personal information to third parties.
            </p>
            <h2 className="pb-2 grey-color-v1 text-capitalize font-16">
                Sensitive Personal Information
            </h2>
            <p className="font-16 mb-4">
                We collect sensitive information including government-issued IDs, like
                your driver’s license or passport number, or social security number, age,
                ethnicity, race and biometric information. We may also collect health
                information (for example, medical conditions and disability status) and
                certain employment information which may also be considered sensitive
                information. We collect categories of sensitive personal information when
                you provide it to us, and will only use it for the purposes disclosed at
                the time of collection.
            </p>
            <h2 className="pb-2 grey-color-v1 text-capitalize font-16">
                Children’s Privacy
            </h2>
            <p className="font-16 mb-4">
                CFS does not target its Services to children under 18 and we do not
                knowingly collect, use or disclose personal information from children
                under 18.
            </p>
            <h2 className="pb-2 grey-color-v1 text-capitalize font-16">
                Retention of Your Personal Information
            </h2>
            <p className="font-16 mb-4">
                For each of these categories of personal information, we retain your
                information for as long as it is reasonably necessary for the purposes for
                which the information was collected, and in accordance with applicable
                retention policies. The criteria used to determine our retention policies
                include the length of our relationship with you, your requests, ongoing
                contract and service obligations, and our legal obligations, business need
                and our legal obligations. Generally, we will retain your
                employment-related personal information until the end of your employment
                with us, plus a reasonable period of time after that where necessary to
                respond to any employment inquiries, deal with legal, tax, accounting or
                administrative matters, or to provide you with ongoing pensions or other
                benefits. We also retain information as otherwise required by applicable
                law.
            </p>
            <h2 className=" pb-2 grey-color-v1 text-capitalize font-16">
                Sources of Your Personal Information
            </h2>
            <p className="font-16">
                As described above in the “The Information We Collect and How We Collect
                It” section, we collect information directly from you when you provide it
                to us, automatically using cookies and other tracking technologies, from
                the categories of third parties listed in that section, and from publicly
                or commercially available sources that we deem credible.
            </p>
            <p className="font-16 mb-4">
                For further information, you can also see the “The Information We Collect
                and How We Collect It” section above.
            </p>
            <h2 className="pb-2 grey-color-v1 text-capitalize font-16">
                Purposes for Your Personal Information
            </h2>
            <p className="font-16 mb-4">
                We collect or disclose personal information about you for the business
                purposes further described in the “How We Use The Information We Collect”
                section above.
            </p>
            <h2 className="pb-2 grey-color-v1 text-capitalize font-16">Do Not Track</h2>
            <p className="font-16 mb-4">
                Some browsers offer you the ability to opt out of cookie-based and other
                online tracking, which may be considered a “sale” or “sharing” of your
                personal information. As we do not sell or share any personal information
                as defined by the CPRA, if you activate a browser-based opt-out signal
                sent through the Global Privacy Control, we cannot effectuate your
                request.
            </p>
            <p className="font-16 mb-4">
                The Services are not currently configured to recognize “do not track” or
                other browser-based signals. However, other settings within your browser
                may permit you to block Cookies or other technology that enable websites
                to collect information that can be used to tailor advertisements to your
                interest. Please check your browser’s settings or consult your browser’s
                help information for more details. To learn more about browser tracking
                signals and Do Not Track please visit
                <a href="https://allaboutdnt.com/" className="privacy-anchor-style" target="_blank">
                    {" "}
                    www.allaboutdnt.org.
                </a>
            </p>
            <h2 className="pb-2 grey-color-v1 text-capitalize font-16">
                Your California Privacy Rights
            </h2>
            <p className="font-16 mb-4">
                If you are a California resident, you have the right, under the California
                Privacy Rights Act (“CPRA”), subject to certain exemptions, to:
            </p>
            <ul>
                <li>
                    Access, request and receive a portable copy of the personal information
                    that we have collected about you in the last 12 months, and
                    specifically:
                    <ul className="mt-2 mb-2 privacy-ul-style">
                        <li>
                            The categories and specific pieces of personal information we have
                            collected about you;
                        </li>
                        <li>The categories of the sources of the personal information;</li>
                        <li>
                            The purposes for which your personal information is collected and
                            used;
                        </li>
                        <li>
                            The categories of personal information that the business disclosed
                            for a business purpose, and for each category identified, the
                            categories of third parties to whom that particular category of
                            personal information was disclosed;
                        </li>
                        <li>
                            The categories of personal information that the business sold or
                            shared, and for each category identified, the categories of third
                            parties to whom that particular category of personal information was
                            sold or shared;
                        </li>
                    </ul>
                </li>
                <li>Correct inaccuracies in your personal information;</li>
                <li>Delete your personal information;</li>
                <li>
                    Not be discriminated against by us for exercising any of these rights.
                </li>
            </ul>
            <h2 className="pb-2 grey-color-v1 text-capitalize font-16">
                Right to Request Deletion of Personal Information
            </h2>
            <p className="font-16 mb-4">
                You have a right to request us to delete your personal information. Upon
                verifying the validity of a deletion request, we will honor your request,
                and, if applicable, instruct any service providers and/or third parties to
                which we have disclosed your personal information to delete your personal
                information; however, please be aware that we may need to retain some of
                your information in order to maintain our records, comply with our legal
                obligations, resolve disputes, or enforce our agreements. It may not
                always be possible to completely remove or delete all of your information
                from our databases without some residual data remaining because of backups
                and other reasons. We may also keep a record of correspondence with you
                (for example if you have made a complaint about a product or our Services)
                to protect us from a legal claim.
            </p>
            <h2 className="pb-2 grey-color-v1 text-capitalize font-16">
                Exercising Your Rights
            </h2>
            <p className="font-16 mb-4">
                To submit your CPRA request with respect to personal information collected
                by CFS in connection with use of the Services, please:
                <span className="d-block">
                    email{" "}
                    <a href="mailto:privacy@cfs.canon.com" className="privacy-anchor-style">
                        privacy@cfs.canon.com
                    </a>{" "}
                    ; or
                </span>
                <span className="d-block">
                    call{" "}
                    <a href="tel:1-800-220-0330" className="privacy-anchor-style">
                        1-800-220-0330{" "}
                    </a>
                    .
                </span>
            </p>
            <p className="font-16 mb-4">
                In your request, please identify yourself as a business user of Canon
                Financial Services.
            </p>
            <p className="font-16 mb-4">
                When you submit a request to us, we will verify your identity before
                processing the request. In order to protect your personal information, you
                may be asked to provide two (2) or three (3) pieces of information to
                match our records so that we can reasonably verify your identity.
            </p>
            <h2 className="pb-2 grey-color-v1 text-capitalize font-16">
                Authorized Agents
            </h2>
            <p className="font-16 mb-4">
                If you are a California resident and would like to designate an authorized
                agent to submit certain requests on your behalf, please send an email to
                the following email address:{" "}
                <a href="mailto:privacy@cfs.canon.com" className="privacy-anchor-style">
                    privacy@cfs.canon.com{" "}
                </a>
                . In order for an authorized agent to be verified, you must provide the
                authorized agent with signed, written permission to make such requests or
                a power of attorney. We may also follow up with you to verify your
                identity before processing the authorized agent’s request.
            </p>
            <p className="font-16 mb-4">
                If you are an agent of a California resident and would like to submit a
                request on behalf of a California resident, please submit the request to
                the following email address:{" "}
                <a href="mailto:privacy@cfs.canon.com" className="privacy-anchor-style">
                    privacy@cfs.canon.com
                </a>{" "}
                . After you submit the request, and if the request is not subject to an
                exemption or exception, we will require additional information to verify
                the identity of the California resident, as well as your authority to act
                on behalf of the California resident.
            </p>
            <h2 className="pb-2 grey-color-v1 text-capitalize font-16">
                California Shine the Light Law
            </h2>
            <p className="font-16 mb-4">
                California Civil Code Section 1798.83 gives California residents the right
                to request, from a business with whom the California resident has an
                established business relationship, certain information with respect to the
                types of personal information the business discloses to third parties for
                direct marketing purposes by such third party, and the identities of the
                third parties with whom the business has disclosed such information during
                the immediately preceding year. Canon Financial Services does not disclose
                your personal information to third parties for direct marketing purposes
                by such third party.
            </p>
            <p className="font-16 mb-4">
                Canon Financial Services may disclose your personal information to service
                providers that support our businesses and perform services on our behalf,
                such as companies that deliver email marketing messages that you have
                requested from us. Canon Financial Services does not sell your personal
                information to third parties, and does not share your personal information
                with third parties.
            </p>
            <p className="font-16 mb-4">
                Our U.S. visitors may have shared information with other Canon groups. If
                you are a California resident and wish to exercise your CPRA rights with
                respect to personal information you have shared with CUSA,{" "}
                <a href="https://www.usa.canon.com/business" className="privacy-anchor-style" target='_blank'>
                    click here{" "}
                </a>
                .
            </p>
        </div>
    </>
}
