import axios from "axios";

export async function client(endpoint, body, requestType, appURL) {

    try {

        let url = `${appURL}/${endpoint}`;

        let requestConfig = {
            method: requestType,
            url: url,
            data: body,
            headers: {
                "Content-Type": "application/json"
            }
        }
        const response = await axios(requestConfig);
        return { statusCode: response.status, data: response.data }
    } catch (error) {

        return { error: error.request.status, data: error.message }
    }
}